import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { CognitoInfos } from 'config/amplify/auth'

const infoAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
})

export const useCognitoInfo = () =>
  useQuery<CognitoInfos>({
    queryKey: ['infos'],
    queryFn: async () => {
      const { data } = await infoAxios.get<CognitoInfos>('/infos')
      return data
    },
  })

import { ColumnDef } from '@tanstack/react-table'
import { Stats } from 'api/dto/tenant/tenant-stats'
import { Table } from 'components/table'
import { useTranslation } from 'react-i18next'

export function MembersStats({ stats }: { stats: Stats[] }) {
  const { t } = useTranslation()

  const columns: ColumnDef<Stats>[] = [
    {
      id: 'name',
      header: t('stats.columns.name'),
      accessorKey: 'name',
    },
    {
      id: 'members',
      header: t('stats.columns.members'),
      accessorKey: 'members',
    },
  ]

  return (
    <Table<Stats>
      searchable={false}
      hidePagination
      columns={columns}
      data={stats}
      defaultSort={{
        id: 'members',
        desc: true,
      }}
    />
  )
}

import { Image } from '@mantine/core'
import { useGetTenant } from 'api/query/tenant'

export function Banner() {
  const { data: tenant } = useGetTenant()

  if (!tenant?.banner) {
    return
  }

  return <Image pos="absolute" top={0} w={1200} h={700} src={tenant?.banner} alt="login" />
}

import { MembershipDto } from 'api/dto'
import { DateTime } from 'utils/date-time'
import { MembershipStatus } from './membership-status'
import { PaymentType } from './payment-type'

export class Membership {
  planId: string
  startDate: DateTime
  endDate?: DateTime
  status: MembershipStatus
  paymentType?: PaymentType
  priceId: string
  subscriptionScheduleId?: string
  newPlanId?: string
  newPriceId?: string

  constructor(membershipDto: MembershipDto) {
    this.planId = membershipDto.planId
    this.startDate = DateTime.fromISOString(membershipDto.startDate)
    this.endDate = membershipDto.endDate ? DateTime.fromISOString(membershipDto.endDate) : undefined
    this.status = membershipDto.status
    this.paymentType = membershipDto.paymentType
    this.priceId = membershipDto.priceId
    this.subscriptionScheduleId = membershipDto.subscriptionScheduleId
    this.newPlanId = membershipDto.newPlanId
    this.newPriceId = membershipDto.newPriceId
  }

  get hasUnpaidMembership() {
    return this.status !== MembershipStatus.SUBSCRIBED && !this.endDate
  }

  isSubscribed() {
    return this.status === MembershipStatus.SUBSCRIBED
  }

  get nextBillingPeriod() {
    return this.endDate
  }
}

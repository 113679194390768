import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Box, Table } from '@mantine/core'
import { DragHandle } from './drag-handle'
import { Row, flexRender } from '@tanstack/react-table'
import { createStyles } from '@mantine/emotion'

const useDraggableRow = createStyles(() => ({
  draggingRow: {
    background: 'rgba(127, 207, 250, 0.3)',
  },
  tableData: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
  },
}))

interface DraggableTableRowProps<T> {
  row: Row<T>
  onRowClick: () => void
}

export function DraggableTableRow<T extends { id: string }>({ row, onRowClick }: DraggableTableRowProps<T>) {
  const { classes } = useDraggableRow()
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  }

  return (
    <Table.Tr
      className={isDragging ? classes.draggingRow : ''}
      ref={setNodeRef}
      style={{ ...style, position: 'relative' }}
      onClick={onRowClick}
    >
      {isDragging
        ? row.getVisibleCells().map((cell, i) => {
            if (i === 0) {
              return (
                <Table.Td>
                  <Box display="flex" sx={{ alignItems: 'center', height: '100%' }}>
                    <DragHandle {...attributes} {...listeners} />
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Box>
                </Table.Td>
              )
            }

            return (
              <Table.Td key={cell.id} style={{ verticalAlign: 'middle', fontSize: '10px' }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Table.Td>
            )
          })
        : row.getVisibleCells().map((cell, i) => {
            if (i === 0) {
              return (
                <Table.Td className={classes.tableData}>
                  <Box display="flex" sx={{ alignItems: 'center', height: '100%' }}>
                    <DragHandle {...attributes} {...listeners} />
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Box>
                </Table.Td>
              )
            }

            return (
              <Table.Td key={cell.id} style={{ verticalAlign: 'middle', fontSize: '10px' }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Table.Td>
            )
          })}
    </Table.Tr>
  )
}

import { Affix, AppShell, Box, Button, Group, Text, rem } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useGetMember } from 'api/query/member'
import { useGetTenant } from 'api/query/tenant'
import { FeatureRequestModal } from 'components/feature/feature-request.modal'
import { MainLinks } from 'components/links/main-links'
import { Loading } from 'components/loading/loading'
import { Logo } from 'components/logo'
import { UserAvatar } from 'components/user/user-avatar'
import { UserProfileModal } from 'components/user/user-profile.modal'
import { useAuth } from 'hooks/useAuth'
import { t } from 'i18next'
import { Outlet } from 'react-router'

export function MemberLayout() {
  const { data: tenant } = useGetTenant()
  const { user: userToken } = useAuth()
  const { data: member, isLoading: isLoadingMember } = useGetMember(userToken?.memberId)

  const [openedRequestModal, { open: openRequestModal, close: closeRequestModal }] = useDisclosure(false)
  const [openedUserModal, { open: openUserModal, close: closeUserModal }] = useDisclosure(false)

  if (isLoadingMember) {
    return <Loading size="lg" height="100vh" />
  }

  return (
    <AppShell
      padding="xl"
      header={{
        height: 60,
      }}
      navbar={member?.hasMembership ? { width: 250, breakpoint: 0 } : undefined}
    >
      <AppShell.Header>
        <Group style={{ height: '100%' }} px={20} justify="space-between">
          <Logo />

          <Box
            display="flex"
            sx={{
              alignItems: 'center',
            }}
          >
            <UserAvatar openUserModal={openUserModal} openRequestModal={openRequestModal} />
          </Box>
        </Group>
      </AppShell.Header>
      {member?.hasMembership && (
        <AppShell.Navbar>
          <AppShell.Section grow mt="md">
            <MainLinks />
          </AppShell.Section>
          <AppShell.Section p={10}>
            <Button
              size="sm"
              mb={10}
              p={5}
              ta="center"
              radius="sm"
              w="100%"
              onClick={(e) => {
                window.open(`mailto:${tenant?.supportEmail}`)
                e.preventDefault()
              }}
            >
              {t('common.contact-us')}
            </Button>
          </AppShell.Section>
        </AppShell.Navbar>
      )}

      {!member?.hasMembership && (
        <Affix w={200} bottom={20} left={20}>
          <Button
            size="sm"
            mb={10}
            p={5}
            ta="center"
            radius="sm"
            w="100%"
            onClick={(e) => {
              window.open(`mailto:${tenant?.supportEmail}`)
              e.preventDefault()
            }}
          >
            {t('common.contact-us')}
          </Button>
        </Affix>
      )}

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
      <FeatureRequestModal opened={openedRequestModal} onClose={closeRequestModal} />
      <UserProfileModal isOpen={openedUserModal} onClose={closeUserModal} />
    </AppShell>
  )
}

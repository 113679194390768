/* eslint-disable @typescript-eslint/naming-convention */
import { Box, TextInput, Button, Group } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Member, PartialMember } from 'api/domain/entities/member'
import { MemberFactory } from 'api/dto/member'
import { useGetMember, useUpdateMember } from 'api/query/member'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export function Contact() {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { data: member, isLoading: isLoadingMember } = useGetMember(id)
  const { mutateAsync: updateMember } = useUpdateMember()
  const form = useForm<Member>({
    validate: {
      contact: (value) => {
        if (!value) {
          return t('common.required')
        }

        if (!value.firstName) {
          return t('common.required')
        }

        if (!value.lastName) {
          return t('common.required')
        }

        if (!value.email) {
          return t('common.required')
        }
      },
    },
  })

  async function onSubmit(member: PartialMember) {
    const updateMemberDto = MemberFactory.toDto({
      ...member,
    })
    await updateMember(updateMemberDto)
    close()
  }

  useEffect(() => {
    if (member) {
      form.setValues(member)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member])

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box mt={10} display="flex" sx={{ gap: 5 }}>
        <TextInput
          withAsterisk
          w="100%"
          label={t('member.fields.contact.firstName')}
          {...form.getInputProps('contact.firstName')}
        />
        <TextInput
          withAsterisk
          w="100%"
          label={t('member.fields.contact.lastName')}
          {...form.getInputProps('contact.lastName')}
        />
      </Box>
      <Box mt={10} display="flex">
        <TextInput
          w="100%"
          withAsterisk
          type="email"
          label={t('member.fields.contact.email')}
          {...form.getInputProps('contact.email')}
        />
      </Box>
      <Box mt={10} display="flex">
        <TextInput w="100%" label={t('member.fields.contact.jobTitle')} {...form.getInputProps('contact.jobTitle')} />
      </Box>
      <Group pt={20} justify="right">
        <Button type="submit" size="xs">
          {t('common.update')}
        </Button>
      </Group>
    </form>
  )
}

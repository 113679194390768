import { PlanDto } from 'api/dto'
import { Price } from './price'

export class Plan {
  id: string
  name: string
  description: string
  paymentProviderId?: string
  prices: Price[]
  order: number
  mostPopular?: boolean
  includedPlanId?: string
  features?: string[]

  includedPlanName?: string

  constructor(planDto: PlanDto, plans?: PlanDto[]) {
    this.id = planDto.id
    this.name = planDto.name
    this.description = planDto.description
    this.prices = planDto.prices
    this.paymentProviderId = planDto.paymentProviderId
    this.order = planDto.order || 0
    this.mostPopular = planDto.mostPopular
    this.includedPlanId = planDto.includedPlanId
    this.features = planDto.features

    if (this.includedPlanId && plans) {
      const includedPlan = plans.find((plan) => plan.id === this.includedPlanId)
      this.includedPlanName = includedPlan?.name
    }
  }

  getPriceWithInterval(interval: string): Price | undefined {
    const price = this.prices?.find((price) => price.interval == (interval as Price['interval']))
    if (!price) {
      return
    }

    return price
  }
}

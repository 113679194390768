/* eslint-disable @typescript-eslint/naming-convention */
import type { MantineColorsTuple, MantineThemeOverride } from '@mantine/core'
import { Tenant } from 'api/domain/entities/tenant'

type ColorTuple = [string, string, string, string, string, string, string, string, string, string]

export const theme = (tenant?: Tenant) => {
  let colorTheme = 'custom-primary-color'
  let customPrimaryColor: ColorTuple | undefined

  if (tenant && tenant.theme) {
    if (tenant.theme.includes(',')) {
      const colors = tenant.theme.split(',')
      if (colors.length === 10) {
        customPrimaryColor = colors as ColorTuple
      } else {
        customPrimaryColor = [...colors, ...Array(10 - colors.length).fill('#FFFFFF')] as ColorTuple
      }
    } else {
      colorTheme = tenant.theme
    }
  }

  const overrides: MantineThemeOverride = {
    primaryColor: colorTheme,
    fontFamily: 'Lato',

    colors: {
      'custom-primary-color': customPrimaryColor || [
        '#F0F5FF',
        '#D6E4FF',
        '#ADC6FF',
        '#85A5FF',
        '#597EF7',
        '#3366CC',
        '#254EA8',
        '#1D3D87',
        '#132C66',
        '#0C1A46',
      ],
    },
  }

  return overrides
}

/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Anchor, Breadcrumbs, Tabs, Title } from '@mantine/core'
import { useGetMember } from 'api/query/member'
import { Loading } from 'components/loading/loading'
import { Contact } from 'components/member/contact'
import { Infos } from 'components/member/infos'
import { Invoices } from 'components/member/invoices'
import { Membership } from 'components/member/membership'
import { Users } from 'components/member/users'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export function Member() {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const { data: member, isLoading: isLoadingMember } = useGetMember(id)

  const items = [
    { title: t('common.menu.members'), href: '/members' },
    { title: `${member?.name}`, href: `/members/${member?.id}` },
  ].map((item, index) => (
    <Anchor href={item.href} key={index}>
      {item.title}
    </Anchor>
  ))

  if (isLoadingMember) {
    return <Loading size="lg" />
  }

  return (
    <>
      <Title>{t('member.edit')}</Title>
      <Breadcrumbs mb={20} separator="→" mt="sm">
        {items}
      </Breadcrumbs>
      <Tabs keepMounted={false} variant="outline" defaultValue="infos">
        <Tabs.List>
          <Tabs.Tab p={10} value="infos">
            {t('member.tabs.infos')}
          </Tabs.Tab>
          <Tabs.Tab p={10} value="contact">
            {t('member.tabs.contact')}
          </Tabs.Tab>
          <Tabs.Tab p={10} value="users">
            {t('member.tabs.users')}
          </Tabs.Tab>
          <Tabs.Tab p={10} value="membership">
            {t('member.tabs.membership')}
          </Tabs.Tab>
          <Tabs.Tab p={10} value="invoices">
            {t('member.tabs.invoices')}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel maw={600} value="infos">
          <Infos />
        </Tabs.Panel>
        <Tabs.Panel maw={600} value="contact">
          <Contact />
        </Tabs.Panel>
        <Tabs.Panel value="users">
          <Users />
        </Tabs.Panel>

        <Tabs.Panel value="membership">
          <Membership />
        </Tabs.Panel>
        <Tabs.Panel value="invoices">
          <Invoices />
        </Tabs.Panel>
      </Tabs>
    </>
  )
}

import { TextInput, Group, Button, Text, Box, Anchor } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IconPassword } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'
import { TenantTitle } from 'components/tenant/tenant-title'
import { encryptData } from 'utils/crypto'
import { Logo } from 'components/logo'
import { Banner } from 'components/banner/banner'

interface FormValues {
  encryptedData: string
  password: string
  confirmPassword: string
}

export const ResetPassword = () => {
  const { completeForgotPassword } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)
  const isSmallDevice = useMediaQuery('(max-width: 864px)')

  const form = useForm({
    initialValues: {
      encryptedData: '',
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: (value) => (value.length >= 8 ? null : 'Password is too short'),
      confirmPassword: (value, values) => (value === values.password ? null : 'Passwords do not match'),
    },
  })

  useEffect(() => {
    const encryptedData = searchParams.get('_d')

    if (!encryptedData) {
      navigate('/session/login')
      return
    }

    form.setFieldValue('encryptedData', encryptedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  async function onSubmit(values: FormValues) {
    const { encryptedData, password } = values
    setIsLoading(true)
    const encryptedPassword = encryptData({ password }, encryptedData)
    await completeForgotPassword(encryptedData, encryptedPassword)
    setIsLoading(false)
  }

  if (isSmallDevice) {
    return (
      <Box w="100%" display="flex" sx={{ flexDirection: 'column' }}>
        <Box pt={30} pl={30}>
          <TenantTitle />
        </Box>
        <Box
          pl={30}
          pr={30}
          mt={20}
          mb={20}
          h={140}
          sx={(theme) => ({
            background: theme.colors[theme.primaryColor][5],
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          })}
        >
          <Text ta="center" fw={400} size="30px" c="white">
            {t('authentication.register-now')}
          </Text>
        </Box>
        <ResetPasswordForm form={form} isLoading={isLoading} onSubmit={onSubmit} />
        <Box>
          <Text ta="center" mt={20} size="sm">
            {t('authentication.register.already-have-account')}{' '}
            <Anchor underline="always" size="sm" onClick={() => navigate('/session/login')}>
              {t('authentication.register.login-now')}
            </Anchor>
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box pos="relative" left={-40} display="flex" sx={{ alignItems: 'center' }}>
      <Box
        w={500}
        display="flex"
        h={700}
        right={-80}
        sx={(theme) => ({
          background: theme.colors[theme.primaryColor][5],
          overflow: 'hidden',
          flexFlow: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          paddingTop: '237px',
          position: 'relative',
        })}
      >
        <Banner />
      </Box>

      <Box
        h={650}
        w={500}
        bg="white"
        display="flex"
        sx={{
          flex: 1,
          flexFlow: 'column',
          padding: '20px 30px',
          zIndex: 100,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
      >
        <Logo width={100} />
        <Box mt={10} mb={20} sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Text ta="center" fw={400} size="xl">
            {t('authentication.reset-password.title')}
          </Text>
        </Box>

        <ResetPasswordForm form={form} isLoading={isLoading} onSubmit={onSubmit} />

        <Text ta="center" mt={20} size="sm">
          {t('authentication.reset-password.already-have-account')}{' '}
          <Anchor underline="always" size="sm" onClick={() => navigate('/session/login')}>
            {t('authentication.reset-password.login')}
          </Anchor>
        </Text>
      </Box>
    </Box>
  )
}

interface ResetPasswordFormProps {
  form: UseFormReturnType<
    {
      encryptedData: string
      password: string
      confirmPassword: string
    },
    (values: { encryptedData: string; password: string; confirmPassword: string }) => {
      encryptedData: string
      password: string
      confirmPassword: string
    }
  >
  onSubmit: (values: FormValues) => Promise<void>
  isLoading: boolean
}

function ResetPasswordForm({ form, isLoading, onSubmit }: ResetPasswordFormProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Box display="flex" sx={{ width: '100%', justifyContent: 'center' }}>
      <Box w="80%" maw={650}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            mb={5}
            size="sm"
            sx={{
              input: {
                borderRadius: '4px !important',
              },
            }}
            leftSection={<IconPassword width={15} height={15} stroke="#cecece" />}
            type="password"
            label={t('authentication.reset-password.fields.password') || ''}
            {...form.getInputProps('password')}
          />
          <TextInput
            mb={5}
            size="sm"
            sx={{
              input: {
                borderRadius: '4px !important',
              },
            }}
            leftSection={<IconPassword width={15} height={15} stroke="#cecece" />}
            type="password"
            label={t('authentication.reset-password.fields.confirmPassword') || ''}
            {...form.getInputProps('confirmPassword')}
          />

          <Group mt={20} align="center">
            <Button
              w="100%"
              size="sm"
              radius="sm"
              type="submit"
              loading={isLoading}
              sx={{
                lineHeight: '22px',
                fontWeight: 400,
              }}
            >
              {t('authentication.reset-password.submit')}
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  )
}

import { DefaultMantineColor } from '@mantine/core'
import { TenantDto } from 'api/dto'

export class Tenant {
  id: string
  paymentProviderId: string
  name: string
  slug: string
  supportEmail: string
  logo?: string
  banner?: string
  theme?: DefaultMantineColor

  constructor(tenantDto: TenantDto) {
    this.id = tenantDto.id
    this.paymentProviderId = tenantDto.paymentProviderId
    this.name = tenantDto.name
    this.slug = tenantDto.slug
    this.supportEmail = tenantDto.supportEmail
    this.logo = tenantDto.logo
    this.banner = tenantDto.banner
    this.theme = tenantDto.theme
  }
}

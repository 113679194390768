import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'config/axios'
import { useCallback } from 'react'
import { globalQueryClient } from './client'
import { showNotification } from '@mantine/notifications'
import { TenantDto } from 'api/dto'
import { isLocalhost, isPR } from 'utils/localhost'
import { Tenant } from 'api/domain/entities/tenant'
import { TenantStatsDto } from 'api/dto/tenant/tenant-stats'
import { TenantStats } from 'api/domain/entities/tenant/tenant-stats'

const tenantClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
})

export const useGetTenant = () => {
  let tenantSlug = isLocalhost() || isPR() ? localStorage.getItem('tenant-slug') : location.host.split('.')[0]

  if ((isLocalhost() || isPR()) && !tenantSlug) {
    // only in dev
    localStorage.setItem('tenant-slug', 'demo')
    tenantSlug = 'demo'
  }

  return useQuery<TenantDto, Error, Tenant>({
    queryKey: ['tenant', tenantSlug],
    queryFn: async () => {
      const response = await tenantClient.get(`/tenants/${tenantSlug}`)
      return response.data
    },
    select: useCallback((tenantDto: TenantDto) => new Tenant(tenantDto), []),
    enabled: !!tenantSlug,
    staleTime: 1000000, // 1000 sec
  })
}

export const useGetTenantStats = () => {
  return useQuery<TenantStatsDto, Error, TenantStats>({
    queryKey: ['tenant', 'me', 'stats'],
    queryFn: async () => {
      const response = await axios.get('/tenants/me/stats')
      return response.data
    },
    select: useCallback((tenantStatsDto: TenantStatsDto) => new TenantStats(tenantStatsDto), []),
  })
}

export const useUpdateTenant = () =>
  useMutation({
    mutationFn: async (tenant: Tenant) => {
      const response = await axios.put(`/tenants/${tenant.id}`, tenant)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Tenant updated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenants'],
      })
    },
  })

import { showNotification } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import { User } from 'api/domain/entities/user'
import { UserDto } from 'api/dto'
import axios from 'config/axios'
import { useCallback } from 'react'
import { globalQueryClient } from './client'
import { useParams } from 'react-router-dom'

export const useGetUserMe = () => {
  return useQuery({
    queryKey: ['users', 'me'],
    queryFn: async () => {
      const { data } = await axios.get<UserDto>('/users/me')
      return data
    },
    select: useCallback((data: UserDto) => new User(data), []),
  })
}

export const useUpdateUserMe = () => {
  return useMutation({
    mutationFn: async (user: Partial<UserDto>) => {
      const { data } = await axios.put<UserDto>('/users/me', user)
      return data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'User updated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['users', 'me'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['users', 'me'],
      })
    },
  })
}

export const useListTenantUsers = () => {
  return useQuery({
    queryKey: ['tenant', 'users', 'me'],
    queryFn: async () => {
      const response = await axios.get('/tenants/me/users')
      return response.data
    },
    select: useCallback((users: UserDto[]) => {
      return users.map((user) => new User(user))
    }, []),
  })
}

export const useListMemberUsers = (memberId?: string) => {
  return useQuery({
    queryKey: ['members', memberId, 'users'],
    queryFn: async () => {
      const response = await axios.get(`/members/${memberId}/users`)
      return response.data
    },
    select: useCallback((users: UserDto[]) => {
      return users.map((user) => new User(user))
    }, []),
    enabled: !!memberId,
  })
}

export const useListMemberMeUsers = () => {
  return useQuery({
    queryKey: ['member', 'users', 'me'],
    queryFn: async () => {
      const response = await axios.get('/members/me/users')
      return response.data
    },
    select: useCallback((users: UserDto[]) => {
      return users.map((user) => new User(user))
    }, []),
  })
}

export const useCreateTenantUser = () => {
  return useMutation({
    mutationFn: async (user: Partial<UserDto>) => {
      const response = await axios.post('/tenants/me/users', user)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'User created',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenant', 'users', 'me'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['tenant', 'users', 'me'],
      })
    },
  })
}

export const useCreateMemberUser = (memberId?: string) => {
  return useMutation({
    mutationFn: async (user: Partial<UserDto>) => {
      const url = memberId ? `/members/${memberId}/users` : '/members/me/users'
      const response = await axios.post(url, user)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'User created',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: memberId ? ['members', memberId, 'users'] : ['member', 'users', 'me'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: memberId ? ['members', memberId, 'users'] : ['member', 'users', 'me'],
      })
    },
  })
}

export const useDeleteTenantUser = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await axios.delete(`/tenants/me/users/${id}`)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'User deleted',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['users', 'tenant', 'me'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['users', 'tenant', 'me'],
      })
    },
  })
}

export const useDeleteMemberUser = (memberId?: string) => {
  return useMutation({
    mutationFn: async (id: string) => {
      const url = memberId ? `/members/${memberId}/users/${id}` : `/members/me/users/${id}`
      const response = await axios.delete(url)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'User deleted',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: memberId ? ['members', memberId, 'users'] : ['member', 'users', 'me'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: memberId ? ['members', memberId, 'users'] : ['member', 'users', 'me'],
      })
    },
  })
}

export const useImportMemberUsers = (memberId?: string) => {
  return useMutation({
    mutationFn: async (csv: string) => {
      const url = memberId ? `/members/${memberId}/import-users` : '/members/me/import-users'
      const response = await axios.post(url, csv)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Users imported',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: memberId ? ['members', memberId, 'users'] : ['member', 'users', 'me'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: memberId ? ['members', memberId, 'users'] : ['member', 'users', 'me'],
      })
    },
  })
}

export const useUpdateUser = (userId?: string) => {
  const { id: memberId } = useParams()
  return useMutation({
    mutationFn: async (user: Partial<UserDto>) => {
      const response = await axios.put(`/users/${userId}`, user)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'User updated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: memberId ? ['members', memberId, 'users'] : ['users', 'me'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: memberId ? ['members', memberId, 'users'] : ['users', 'me'],
      })
    },
  })
}

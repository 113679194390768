import { useEffect, useMemo, useState, ReactNode } from 'react'
import { Box, Table as MantineTable, Text, TextInput, Select, ActionIcon, ActionIconProps } from '@mantine/core'
import {
  useReactTable,
  Row,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  FilterFn,
  TableState,
  Column,
  Table as ReactTable,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { useTableStyles } from './table.styles'
import { useDebouncedState } from '@mantine/hooks'
import { IconArrowUp, IconArrowDown, IconSearch, IconTableExport } from '@tabler/icons-react'
import { DatePickerInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { exportToCsv } from 'utils/export'

import { RankingInfo, rankItem, compareItems } from '@tanstack/match-sorter-utils'

declare module '@tanstack/react-table' {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

export type ColumnWithFilter<T> = ColumnDef<T> & {
  filter?: (row: any, columnId: string, value: any) => boolean
}

interface ITableProps<T extends object> {
  columns: ColumnWithFilter<T>[]
  data: T[]
  setFilteredRows?: (rows: T[]) => void
  defaultSort?: { id: string; desc: boolean }
  hiddenColumns?: Record<string, boolean>
  actionButtons?: (rows: Row<T>[]) => ReactNode[]
  onRowClick?: (row: Row<T>) => void
  searchable?: boolean
  hidePagination?: boolean

  title?: React.ReactNode
  multiple?: boolean
  selected?: string | string[]

  exportTable?: {
    filename: string
    customParser?: (row: any) => any
  }
}

interface GlobalFilterProps<T extends object> {
  filteredRows: Row<T>[]
  globalFilter: string | number | readonly string[] | undefined
  setGlobalFilter: (value: string) => void
}

// Define a default UI for filtering
function GlobalFilter<T extends object>(props: GlobalFilterProps<T>) {
  const { t } = useTranslation()
  const { classes } = useTableStyles({ hasFilters: false })
  const { globalFilter, setGlobalFilter, filteredRows: preGlobalFilteredRows } = props
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const [debounced, setDebouncedVal] = useDebouncedState('', 200, undefined)

  useEffect(() => {
    setGlobalFilter(debounced)
  }, [setGlobalFilter, debounced])

  return (
    <Box className={classes.search}>
      <Box className={classes.searchInput}>
        <IconSearch color="#cecece" />
        <TextInput
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            setDebouncedVal(e.target.value)
          }}
          placeholder={t('common.search')}
        />
      </Box>
      <Box className={classes.result}>
        <Text>{count}</Text>
        <Text ml={3}>{t('common.results')}</Text>
      </Box>
    </Box>
  )
}

export function Table<T extends object>(props: ITableProps<T>) {
  const { t } = useTranslation()
  const [globalFilter, setGlobalFilter] = useState('')
  const [filters, setFilters] = useState<Record<string, string[]>>({})
  const [rowSelection, setRowSelection] = useState({})

  const { classes, cx } = useTableStyles({
    hasFilters: Object.keys(filters).length > 0,
  })
  const {
    columns,
    data,
    actionButtons,
    defaultSort,
    hiddenColumns,
    onRowClick,
    searchable = true,
    multiple,
    exportTable,
    hidePagination,
    title,
  } = props

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const table = useReactTable<T>({
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: !hidePagination ? getPaginationRowModel() : undefined,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: multiple,
    columns,
    data,
    state: {
      globalFilter,
      rowSelection,
    },
    globalFilterFn: 'fuzzy',
    initialState: {
      pagination: !hidePagination ? { pageSize: 10 } : undefined,
      columnVisibility: hiddenColumns,
      sorting: defaultSort ? [defaultSort] : [],
    },
  })

  const {
    getHeaderGroups,
    getCanPreviousPage,
    getCanNextPage,
    nextPage,
    previousPage,
    getState,
    setPageSize,
    getRowModel,
  } = table

  const columnWithSize = useMemo(
    () =>
      columns
        .filter((c) => !!c.id)
        .map((c) => ({ [c.id!]: c }))
        .reduce((prev, val) => Object.assign(prev, val), {}),
    [columns],
  )

  const ExportButton = ({ ...props }: ActionIconProps) => {
    return (
      <ActionIcon
        size={40}
        disabled={getRowModel().rows.length === 0}
        onClick={() =>
          exportToCsv(
            getRowModel().rows.map((row) => {
              let currentRow: any = { ...row.original }

              if (exportTable?.customParser) {
                currentRow = exportTable.customParser(currentRow)
              }

              return currentRow
            }),
            `${exportTable?.filename}.csv`,
          )
        }
        {...props}
      >
        <IconTableExport />
      </ActionIcon>
    )
  }

  return (
    <>
      {searchable && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {exportTable && <ExportButton h={44} mr={10} />}
          <GlobalFilter
            filteredRows={getRowModel().rows}
            globalFilter={getState().globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          {actionButtons && (
            <Box
              ml={10}
              display="flex"
              sx={{
                alignItems: 'center',
              }}
            >
              {actionButtons(getRowModel().rows)}
            </Box>
          )}
        </Box>
      )}

      <Box data-testId="test-table" className={classes.container}>
        {title && (
          <Box display="flex" sx={{ justifyContent: 'space-between' }}>
            {title}
            {exportTable && <ExportButton size="xs" h={44} mt={10} mr={10} />}
          </Box>
        )}
        <MantineTable sx={{ borderSpacing: 0 }} highlightOnHover>
          <MantineTable.Thead>
            {getHeaderGroups().map((headerGroup) => (
              <MantineTable.Tr className={classes.header} key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <MantineTable.Th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      minWidth: columnWithSize[header.id].minSize,
                      maxWidth: columnWithSize[header.id].maxSize,
                      width: columnWithSize[header.id].size,
                    }}
                  >
                    <Box display="flex" sx={{ flexDirection: 'column' }}>
                      <Box
                        {...{
                          style: header.column.getCanSort()
                            ? {
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                userSelect: 'none',
                              }
                            : {},
                          className: classes.headerText,
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <IconArrowUp style={{ marginLeft: '.375rem' }} />,
                          desc: <IconArrowDown style={{ marginLeft: '.375rem' }} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </Box>
                    </Box>
                  </MantineTable.Th>
                ))}
              </MantineTable.Tr>
            ))}
          </MantineTable.Thead>
          <MantineTable.Tbody>
            {getRowModel().rows.map((row) => {
              return (
                <MantineTable.Tr
                  key={row.id}
                  sx={{
                    cursor: onRowClick ? 'pointer' : 'default',
                  }}
                  onClick={() => onRowClick?.(row)}
                >
                  {row.getVisibleCells().map((cell) => {
                    let styles = {}
                    if (columnWithSize[cell.column.id].maxSize) {
                      styles = {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }
                    }

                    return (
                      <MantineTable.Td
                        key={cell.id}
                        className={classes.text}
                        style={{
                          ...styles,
                          verticalAlign: 'middle',
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </MantineTable.Td>
                    )
                  })}
                </MantineTable.Tr>
              )
            })}
          </MantineTable.Tbody>
        </MantineTable>
      </Box>

      {!props.hidePagination && (
        <Box className={classes.pagination}>
          <Box className={cx(classes.footerBox, 'start')}>
            <Box className={classes.footerBox}>
              <Text
                className={classes.footerText}
              >{`${getRowPosition(true, getState(), getRowModel().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('common.to')}
              </Text>
              <Text
                ml={3}
                className={classes.footerText}
              >{`${getRowPosition(false, getState(), getRowModel().rows.length)}`}</Text>
              <Text ml={3} className={classes.footerText}>
                {t('common.of')}
              </Text>
              <Text ml={3} className={classes.footerText}>
                {getRowModel().rows.length}
              </Text>
            </Box>
            <Box>
              <Select
                size="sm"
                value={getState().pagination.pageSize.toString()}
                data={[
                  { value: '10', label: '10' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                ]}
                onChange={(e) => setPageSize(Number(e))}
              />
            </Box>
          </Box>
          <Box className={classes.footerBox}>
            <Text
              className={cx(classes.footerText, classes.footerTextButton, getCanPreviousPage() ? 'active' : 'inactive')}
              sx={getCanPreviousPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanPreviousPage()) {
                  return
                }
                previousPage()
              }}
            >
              {t('common.previous')}
            </Text>
            <Text
              ml={21}
              className={cx(classes.footerText, classes.footerTextButton, getCanNextPage() ? 'active' : 'inactive')}
              sx={getCanNextPage() ? { color: 'blue' } : { color: 'gray', cursor: 'default' }}
              onClick={() => {
                if (!getCanNextPage()) {
                  return
                }
                nextPage()
              }}
            >
              {t('common.next')}
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

function getRowPosition(firstRow: boolean, tableState: TableState, totalRowCount: number) {
  const {
    pagination: { pageIndex, pageSize },
  } = tableState

  const index = +pageIndex + 1

  if (index === 1) {
    if (totalRowCount === 0) {
      return 0
    }

    return firstRow ? 1 : index * pageSize > totalRowCount ? totalRowCount : index * pageSize
  }

  return firstRow ? pageIndex * pageSize : index * pageSize > totalRowCount ? totalRowCount : index * pageSize
}

function Filter({ column, table }: { column: Column<any, any>; table: ReactTable<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)

  if (typeof firstValue === 'string' && firstValue.includes('-') && dayjs(firstValue).isValid()) {
    return (
      <DatePickerInput
        mt={3}
        pr={6}
        size="xs"
        value={column.getFilterValue() as any}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={'DD-MM-YYYY'}
      />
    )
  }

  return typeof firstValue === 'number' ? (
    <Box mt={3} display="flex" pr={6}>
      <TextInput
        w={50}
        size="xs"
        type="number"
        value={((column.getFilterValue() as any)?.[0] ?? '') as string}
        onChange={(e) => column.setFilterValue((old: any) => [e.target.value, old?.[1]])}
      />
      <TextInput
        w={50}
        type="number"
        size="xs"
        ml={3}
        value={((column.getFilterValue() as any)?.[1] ?? '') as string}
        onChange={(e) => column.setFilterValue((old: any) => [old?.[0], e.target.value])}
      />
    </Box>
  ) : (
    <TextInput
      mt={3}
      pr={6}
      size="xs"
      type="text"
      value={(column.getFilterValue() ?? '') as string}
      onChange={(e) => {
        column.setFilterValue(e.target.value)
      }}
      placeholder={'Search...'}
    />
  )
}

import { ContactDto } from 'api/dto/contact/contact-dto'

export class Contact {
  firstName: string
  lastName: string
  email: string
  jobTitle?: string

  constructor(contactDto: ContactDto) {
    this.firstName = contactDto.firstName
    this.lastName = contactDto.lastName
    this.email = contactDto.email
    this.jobTitle = contactDto.jobTitle
  }
}

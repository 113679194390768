import { Tenant } from 'api/domain/entities/tenant'
import { TenantDto } from './tenant-dto'

export class TenantFactory {
  static fromDto(tenantDto: TenantDto): Tenant {
    return new Tenant(tenantDto)
  }

  static toDto(tenant: Tenant): TenantDto {
    return {
      id: tenant.id,
      paymentProviderId: tenant.paymentProviderId,
      name: tenant.name,
      slug: tenant.slug,
      supportEmail: tenant.supportEmail,
      logo: tenant.logo,
      banner: tenant.banner,
      theme: tenant.theme,
    }
  }
}

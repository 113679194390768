import { CategoryDto } from 'api/dto'

export class Category {
  id: string
  name: string
  description: string

  constructor(categoryDto: CategoryDto) {
    this.id = categoryDto.id
    this.name = categoryDto.name
    this.description = categoryDto.description
  }
}

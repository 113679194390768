import { ActionIcon, Affix, Badge, Button, Pill, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconFilter, IconFilterCancel, IconPlus } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { Member } from 'api/domain/entities/member'
import { MemberStatus } from 'api/domain/entities/member/member-status'
import { MembershipStatus } from 'api/domain/entities/membership/membership-status'
import { useListCategories } from 'api/query/category'
import { useListMembers } from 'api/query/member'
import { useListPlans } from 'api/query/plan'
import { AddMemberDrawer } from 'components/drawer/add.member'
import { Loading } from 'components/loading/loading'
import { FiltersModal } from 'components/modal/filters'
import { Table } from 'components/table'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DateTime, DateTimeFormat } from 'utils/date-time'

export function Members() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: members, isLoading: isLoadingMembers } = useListMembers()
  const { data: categories, isLoading: isLoadingCategories } = useListCategories()
  const { data: plans, isLoading: isLoadingPlans } = useListPlans()
  const [addMemberDrawerOpened, { open: openAddMemberDrawer, close: closeAddMemberDrawer }] = useDisclosure(false)
  const [filters, setFilters] = useState<Record<string, any>>({})
  const [filtersOpened, { open: openFilters, close: closeFilters }] = useDisclosure(false)

  const filteredMembers = useMemo(() => {
    if (!members) {
      return []
    }

    return members.filter((member) => {
      if (filters.categoryIds && filters.categoryIds.length > 0) {
        if (!member.categoryIds?.some((categoryId) => filters.categoryIds.includes(categoryId))) {
          return false
        }
      }

      if (filters.statuses && filters.statuses.length > 0) {
        if (!filters.statuses.includes(member.status)) {
          return false
        }
      }

      return true
    })
  }, [members, filters])

  const columns: ColumnDef<Member>[] = [
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: t('member.columns.name'),
      cell: (col) => {
        return <Text size="xs">{col.getValue() as string}</Text>
      },
    },
    {
      id: 'memberSince',
      accessorKey: 'memberSince',
      header: t('member.columns.memberSince'),
      cell: (col) => <Text size="xs">{(col.getValue() as DateTime).toFormat(DateTimeFormat.YearMonthAndDay)}</Text>,
    },
    {
      id: 'categories',
      header: t('member.columns.categories'),
      accessorKey: 'categoryIds',
      filterFn: 'arrIncludes',
      accessorFn: (member) => {
        const categoryIds = member.categoryIds
        if (!categoryIds) {
          return null
        }

        return categoryIds.map((categoryId) => {
          const category = categories.find((category) => category.id === categoryId)

          if (!category) {
            return null
          }

          return category.name
        })
      },
      cell: (col) => {
        const categories = col.getValue() as string[] | undefined

        return categories?.map((category) => (
          <Pill size="xs" mr={2}>
            {category}
          </Pill>
        ))
      },
    },
    {
      id: 'membership',
      accessorKey: 'membership',
      header: t('member.columns.membership'),
      accessorFn: (member) => {
        const membership = member.membership

        if (!membership) {
          return null
        }

        const plan = plans?.find((plan) => plan.id === membership.planId)
        return plan?.name
      },
      cell: (col) => {
        const membership = col.getValue()

        if (!membership) {
          return null
        }

        return <Text size="xs">{membership as string}</Text>
      },
    },
    {
      id: 'renewalDate',
      header: t('member.columns.renewalDate'),
      accessorKey: 'membership',
      accessorFn: (member) => {
        const membership = member.membership

        if (!membership) {
          return null
        }

        const renewalDate = membership.endDate?.toFormat(DateTimeFormat.YearMonthAndDay)
        return renewalDate
      },
      cell: (col) => {
        const endDate = col.getValue() as string

        if (!endDate) {
          return null
        }

        return <Text size="xs">{endDate ? endDate : '-'}</Text>
      },
    },
    {
      id: 'paidBy',
      header: t('member.columns.paidBy'),
      accessorKey: 'membership',
      accessorFn: (member) => {
        const membership = member.membership

        if (!membership) {
          return null
        }

        return membership.paymentType
      },
      cell: (col) => {
        const paymentType = col.getValue() as string

        if (!paymentType) {
          return null
        }

        return <Text size="xs">{paymentType ? t(`membership.paid-by.${paymentType}`) : '-'}</Text>
      },
    },
    {
      id: 'status',
      accessorKey: 'membership.status',
      header: t('member.columns.status'),
      cell: (col) => {
        const status = col.getValue() as MembershipStatus
        if (!status) {
          return null
        }

        let color = 'gray'
        switch (status) {
          case MembershipStatus.SUBSCRIBED:
            color = 'green'
            break
          case MembershipStatus.CANCELED:
            color = 'red'
            break
          case MembershipStatus.EXPIRED:
            color = 'gray'
            break
          case MembershipStatus.IN_PROGRESS:
            color = 'yellow'
            break
        }

        return (
          <Badge size="sm" color={color}>
            {t(`membership.status.${status}`)}
          </Badge>
        )
      },
    },
  ]

  const numberOfFilters = Object.values(filters).reduce((prev: number, b: unknown[]) => prev + b.length, 0)

  const actionButtons = () => [
    <Button leftSection={<IconFilter size={15} />} h={44} size="sm" onClick={openFilters}>
      {t('common.filters')} {numberOfFilters > 0 && `(${numberOfFilters})`}
    </Button>,
    <Button
      disabled={numberOfFilters === 0}
      ml={10}
      leftSection={<IconFilterCancel size={15} />}
      h={44}
      size="sm"
      onClick={() => setFilters({})}
    >
      {t('common.clear-filters')}
    </Button>,
  ]

  if (isLoadingMembers || isLoadingCategories || isLoadingPlans) {
    return <Loading size="lg" />
  }

  return (
    <>
      <Title mb={30} order={2}>
        {t('member.title')}
      </Title>
      <Table
        columns={columns}
        data={filteredMembers}
        hidePagination
        hiddenColumns={{
          createdAt: false,
        }}
        actionButtons={actionButtons}
        onRowClick={(row) => navigate(`/members/${row.original.id}`)}
        exportTable={{
          filename: 'members',
        }}
        defaultSort={{
          id: 'createdAt',
          desc: true,
        }}
      />
      <AddMemberDrawer opened={addMemberDrawerOpened} close={closeAddMemberDrawer} />
      <Affix bottom={20} right={20}>
        <ActionIcon
          radius={100}
          data-testId="add-member"
          display={addMemberDrawerOpened ? 'none' : 'block'}
          size="xl"
          onClick={openAddMemberDrawer}
        >
          <IconPlus size={17} />
        </ActionIcon>
      </Affix>

      <FiltersModal
        isOpen={filtersOpened}
        onClose={closeFilters}
        filters={filters}
        onFilter={(filters) => setFilters(filters)}
      />
    </>
  )
}

import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'config/axios'
import { useCallback } from 'react'
import { globalQueryClient } from './client'
import { showNotification } from '@mantine/notifications'
import { CategoryDto } from 'api/dto'
import { Category } from 'api/domain/entities/category'

export const useListCategories = () => {
  return useQuery<CategoryDto[], Error, Category[]>({
    queryKey: ['categories'],
    queryFn: async () => {
      const response = await axios.get('/categories')
      return response.data
    },
    initialData: [],
    select: useCallback((categories: CategoryDto[]) => categories.map((c) => new Category(c)), []),
  })
}

export const useCreateCategory = () => {
  return useMutation({
    mutationFn: async (category: Partial<CategoryDto>) => {
      const response = await axios.post('/categories', category)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Category created',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['categories'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['categories'],
      })
    },
  })
}

export const useUpdateCategory = () => {
  return useMutation({
    mutationFn: async (category: Partial<CategoryDto>) => {
      const response = await axios.put(`/categories/${category.id}`, category)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Category updated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['categories'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['categories'],
      })
    },
  })
}

export const useDeleteCategory = () => {
  return useMutation({
    mutationFn: async (category: Partial<CategoryDto>) => {
      await axios.delete(`/categories/${category.id}`)
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Category deleted',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['categories'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['categories'],
      })
    },
  })
}

/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Tabs, Title } from '@mantine/core'
import { useGetMember } from 'api/query/member'
import { Loading } from 'components/loading/loading'
import { Infos } from 'components/tenant/infos'
import { Users } from 'components/tenant/users'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export function Administration() {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const { data: member, isLoading: isLoadingMember } = useGetMember(id)

  if (isLoadingMember) {
    return <Loading size="lg" />
  }

  return (
    <>
      <Title mb={30} order={2}>
        {t('common.menu.admin')}
      </Title>
      <Tabs keepMounted={false} variant="outline" defaultValue="infos">
        <Tabs.List>
          <Tabs.Tab p={10} value="infos">
            {t('member.tabs.infos')}
          </Tabs.Tab>
          <Tabs.Tab p={10} value="users">
            {t('member.tabs.users')}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel maw={600} value="infos">
          <Infos />
        </Tabs.Panel>
        <Tabs.Panel value="users">
          <Users />
        </Tabs.Panel>
      </Tabs>
    </>
  )
}

import { useEffect, useState, useContext, createContext, useCallback, useMemo, ReactNode } from 'react'
import { CognitoUser, CognitoIdToken } from 'amazon-cognito-identity-js'
import { Hub } from '@aws-amplify/core'
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { hubCallback } from '../config/amplify/hub'
import { setRequestInterceptor } from '../config/axios'
import { configureAmplify } from '../config/amplify/auth'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { showNotification } from '@mantine/notifications'
import { useCognitoInfo } from 'api/query/cognito'
import { UserToken } from 'api/dto/user-token'
import { useGetTenant } from 'api/query/tenant'
import { UserRole } from 'api/domain/entities/user'
import { encryptData } from 'utils/crypto'
import axios from 'axios'
import { Address } from 'api/domain/entities/member'

const authAxios = axios.create()
authAxios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL as string

interface ICognitoUser {
  email: string
  given_name: string
  family_name: string
}

const authContext = createContext<ReturnType<typeof useProvideAuth>>({} as never)

export function ProvideAuth(): JSX.Element {
  const currentAuth = useProvideAuth()
  return (
    <authContext.Provider value={currentAuth}>
      <Outlet />
    </authContext.Provider>
  )
}

export const useAuth = () => useContext(authContext)

const useProvideAuth = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { data: infos, isLoading: isInfosLoading } = useCognitoInfo()
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenant()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [tempEmail, setTempEmail] = useState('')
  const [federatedSignInLoading, setFederatedSignIn] = useState(false)
  const [isHydrated, setIsHydrated] = useState(false)
  const [tempCognitoUser, setTempCognitoUser] = useState<CognitoUser>()

  const [user, setUser] = useState<UserToken | null>(null)

  const appIsHydrated = useMemo(() => isHydrated, [isHydrated])

  const logout = useCallback(async (): Promise<void> => {
    await Auth.signOut()
    setIsAuthenticated(false)
    navigate('/session/login')
  }, [navigate])

  const extractAndRedirect = useCallback(async () => {
    const session = await Auth.currentSession()
    setRequestInterceptor(logout)

    if (!session) {
      return
    }

    const user = extractUserFromToken(session.getIdToken())

    setUser(user)
    setIsAuthenticated(true)

    if (location.pathname.includes('session')) {
      navigate('/dashboard')
    }
  }, [location, logout, navigate])

  const hydrateUser = useCallback(async () => {
    if (isInfosLoading || isLoadingTenant) {
      return
    }

    configureAmplify(infos)

    try {
      await extractAndRedirect()
    } catch (error) {
      // console.log("no user")
    }

    if (location.pathname.includes('/change-password') && !tempCognitoUser) {
      navigate('/session/login')
    }

    setIsHydrated(true)
  }, [isInfosLoading, isLoadingTenant, infos, location.pathname, tempCognitoUser, extractAndRedirect, navigate])

  const login = useCallback(
    async (email: string, password: string): Promise<void> => {
      try {
        const slug = tenant?.slug

        if (!slug) {
          showNotification({
            title: 'Login Failed',
            message: 'Tenant not found, please contact support.',
            color: 'red',
          })
          return
        }

        const cognitoUser: CognitoUser & { challengeName: string } = await Auth.signIn({
          username: email.toLowerCase().trim() + `++${slug}`,
          password,
        })

        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setTempCognitoUser(cognitoUser)
          navigate('/session/change-password')
          return
        }

        const session = await Auth.currentSession()

        if (session) {
          setRequestInterceptor(logout)
          setIsAuthenticated(true)
          setUser(extractUserFromToken(session.getIdToken()))

          navigate('/dashboard')
        } else {
          showNotification({
            title: 'Login Failed',
            message: 'Seems like the email or password is not valid, please try again.',
            color: 'red',
          })
        }
      } catch (error: any) {
        showNotification({
          title: 'Login Failed',
          message: 'Seems like the email or password is not valid, please try again.',
          color: 'red',
        })
      }
    },
    [tenant, navigate, logout],
  )

  const signUp = async (email: string, password: string, user: ICognitoUser & { address: Address }) => {
    const slug = tenant?.slug

    if (!slug) {
      showNotification({
        title: 'Register Failed',
        message: 'Tenant not found, please contact support.',
        color: 'red',
      })
      return
    }

    const username = email.toLowerCase().trim() + `++${slug}`

    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          given_name: user.given_name,
          family_name: user.family_name,
        },
        clientMetadata: {
          ...user,
          address: JSON.stringify(user.address),
        },
      })

      await login(email, password)
    } catch (error) {
      showNotification({
        title: 'Register Failed',
        message: 'An error occurred, please try again.',
        color: 'red',
      })
      return
    }
  }

  const completeActivation = useCallback(
    async (encryptedData: string, password: string): Promise<void> => {
      try {
        const encryptedPassword = encryptData({ password }, encryptedData)
        const { data: email } = await authAxios.post('/users/activate', { encryptedData, encryptedPassword })
        showNotification({
          message: 'Password has been changed successfully',
          color: 'green',
        })

        await login(email, password)
      } catch (error: any) {
        showNotification({
          autoClose: false,
          message: 'Activation failed, the link might be expired, please contact support at support@fyreflex.com.',
          color: 'red',
        })
      }
    },
    [login],
  )

  const completeNewPassword = useCallback(
    async (newPassword: string): Promise<void> => {
      if (!tempCognitoUser) {
        return
      }

      try {
        await Auth.completeNewPassword(tempCognitoUser, newPassword, {})
        showNotification({
          message: 'Password has been changed successfully',
          color: 'green',
        })
        await extractAndRedirect()
      } catch (error: any) {
        if (error.code === 'Code mismatch') {
          showNotification({
            message: 'Code does not match',
            color: 'red',
          })
          return
        }
        showNotification({
          message: 'Change password failed, try again',
          color: 'red',
        })
      }
    },
    [tempCognitoUser, extractAndRedirect],
  )

  const forgotPassword = useCallback(async (tenantSlug: string, email: string): Promise<void> => {
    try {
      await authAxios.post('/users/forgot-password', { slug: tenantSlug, email })
      showNotification({
        title: 'Forgot Password',
        message: 'A link has been sent to your email, please check your email.',
        color: 'blue',
      })
    } catch (error) {
      showNotification({
        title: 'Forgot Password',
        message: 'Seems like the email is not valid, please enter a valid email.',
        color: 'red',
      })
    }
  }, [])

  const completeForgotPassword = useCallback(
    async (encryptedData: string, encryptedPassword: string): Promise<void> => {
      try {
        await authAxios.post('/users/complete-forgot-password', { encryptedData, encryptedPassword })
        showNotification({
          title: 'Change Password',
          message: 'Password has been changed successfully',
          color: 'green',
        })
        navigate('/session/login')
      } catch (error: any) {
        if (error.code === 'CodeMismatchException') {
          showNotification({
            title: 'Change Password',
            message: 'An error occurred, please try again',
            color: 'red',
          })
        }
        showNotification({
          title: 'Change Password',
          message: 'An error occurred, please try again',
          color: 'red',
        })
      }
    },
    [navigate],
  )

  const resetPassword = useCallback(
    async (verificationCode: string, newPassword: string): Promise<void> => {
      if (!tempEmail) {
        return
      }

      try {
        await Auth.forgotPasswordSubmit(tempEmail, verificationCode, newPassword)
        showNotification({
          title: 'Change Password',
          message: 'Password has been changed successfully',
          color: 'green',
        })
        navigate('/session/login')
      } catch (error: any) {
        if (error.code === 'CodeMismatchException') {
          showNotification({
            title: 'Change Password',
            message: 'An error occurred, the code does not match, please try again.',
            color: 'red',
          })
          return
        }
        showNotification({
          title: 'Change Password',
          message: 'An error occurred, please try again',
          color: 'red',
        })
      }
    },
    [tempEmail, navigate],
  )

  const signInWithGoogle = async () => {
    setFederatedSignIn(true)
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
  }

  const completeSignInWithGoogle = () => {
    setIsAuthenticated(true)
    setFederatedSignIn(false)
  }

  useEffect(() => {
    void hydrateUser()
  }, [hydrateUser])

  useEffect(() => {
    const callback = hubCallback(completeSignInWithGoogle, logout)
    const removeAuthListener = Hub.listen('auth', callback)

    return () => removeAuthListener()
  }, [logout])

  return {
    user,
    login,
    completeActivation,
    completeNewPassword,
    logout,
    appIsHydrated,
    signUp,
    forgotPassword,
    completeForgotPassword,
    resetPassword,
    isAuthenticated,
    signInWithGoogle,
    completeSignInWithGoogle,
    federatedSignInLoading,
  }
}

export function extractUserFromToken(token: CognitoIdToken) {
  const payload = token.payload as Record<string, string>

  return new UserToken({
    id: payload.userId,
    email: payload.email,
    isAdmin: payload.isAdmin == 'true',
    tenantId: payload.tenantId,
    memberId: payload.memberId,
    role: payload.userRole as UserRole,
  })
}

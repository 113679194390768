import { TextInput, Group, Button, Text, Box, Image, Anchor } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { Logo } from 'components/logo'
import { IconMail } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'
import { TenantTitle } from 'components/tenant/tenant-title'
import { useGetTenant } from 'api/query/tenant'
import { Banner } from 'components/banner/banner'
import isEmail from 'validator/lib/isEmail'

interface FormValues {
  email: string
}

export const ForgotPassword = () => {
  const { forgotPassword } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenant()
  const [isLoading, setIsLoading] = useState(false)
  const isSmallDevice = useMediaQuery('(max-width: 864px)')

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (isEmail(value) ? null : 'Invalid email'),
    },
  })

  async function onSubmit(values: FormValues) {
    if (!tenant) {
      return
    }

    setIsLoading(true)
    await forgotPassword(tenant.slug, values.email)
    setIsLoading(false)
  }

  if (isSmallDevice) {
    return (
      <Box w="100%" display="flex" sx={{ flexDirection: 'column' }}>
        <Box pt={20} pl={30}>
          <TenantTitle />
        </Box>
        <Box
          pl={30}
          pr={30}
          mt={20}
          mb={20}
          h={140}
          sx={(theme) => ({
            background: theme.colors[theme.primaryColor][5],
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          })}
        >
          <Text ta="center" fw={400} size="30px" c="white">
            {t('authentication.forgot-password.title')}
          </Text>
        </Box>
        <ForgotPasswordForm form={form} isLoading={isLoading} onSubmit={onSubmit} />
        <Box>
          <Text ta="center" mt={20} size="sm">
            {t('authentication.register.already-have-account')}{' '}
            <Anchor underline="always" size="sm" onClick={() => navigate('/session/login')}>
              {t('authentication.register.login-now')}
            </Anchor>
          </Text>
        </Box>
      </Box>
    )
  }

  return (
    <Box pos="relative" left={-40} display="flex" sx={{ alignItems: 'center' }}>
      <Box
        w={500}
        display="flex"
        h={700}
        right={-80}
        sx={(theme) => ({
          background: theme.colors[theme.primaryColor][5],
          overflow: 'hidden',
          flexFlow: 'column',
          alignItems: 'center',
          borderRadius: '8px',
          paddingTop: '237px',
          position: 'relative',
        })}
      >
        <Banner />
      </Box>

      <Box
        h={650}
        w={500}
        bg="white"
        display="flex"
        sx={{
          flex: 1,
          flexFlow: 'column',
          padding: '20px 30px',
          zIndex: 100,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
      >
        <Logo width={100} />
        <Box mt={10} mb={20} sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Text ta="center" fw={400} size="xl">
            {t('authentication.forgot-password.title')}
          </Text>
        </Box>

        <ForgotPasswordForm form={form} isLoading={isLoading} onSubmit={onSubmit} />

        <Text mt={20} ta="center" size="sm">
          {t('authentication.forgot-password.already-have-account')}{' '}
          <Anchor underline="always" size="sm" onClick={() => navigate('/session/login')}>
            {t('authentication.forgot-password.login')}
          </Anchor>
        </Text>
      </Box>
    </Box>
  )
}

interface ForgotPasswordFormProps {
  form: UseFormReturnType<
    {
      email: string
    },
    (values: { email: string }) => {
      email: string
    }
  >
  onSubmit: (values: FormValues) => Promise<void>
  isLoading: boolean
}

function ForgotPasswordForm({ form, isLoading, onSubmit }: ForgotPasswordFormProps) {
  const { t } = useTranslation()
  return (
    <Box display="flex" sx={{ width: '100%', justifyContent: 'center' }}>
      <Box w="80%" maw={650}>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            mb={10}
            size="sm"
            sx={{
              input: {
                borderRadius: '4px !important',
                fontSize: '16px !important',
              },
            }}
            leftSection={<IconMail width={15} height={15} stroke="#cecece" />}
            required
            label={t('authentication.forgot-password.fields.email') || ''}
            {...form.getInputProps('email')}
          />
          <Group mt={20} align="center">
            <Button
              w="100%"
              size="sm"
              radius="sm"
              type="submit"
              loading={isLoading}
              sx={{
                lineHeight: '22px',
                fontWeight: 400,
              }}
            >
              {t('authentication.forgot-password.submit')}
            </Button>
          </Group>
        </form>
      </Box>
    </Box>
  )
}

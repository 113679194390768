import { Button, Group, Modal, Text } from '@mantine/core'
import { usePayInvoiceOutOfBand } from 'api/query/invoice'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface PayInvoiceOutOfBandProps {
  opened: boolean
  onClose: () => void

  invoiceId?: string
}

export function PayInvoiceOutOfBandModal({ opened, onClose, invoiceId }: PayInvoiceOutOfBandProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { mutateAsync: payInvoiceOutOfBand } = usePayInvoiceOutOfBand()

  return (
    <Modal title={t('invoice.pay')} size="md" onClose={onClose} opened={opened}>
      <Modal.Body>
        <Text size="md">
          {t('invoice.are-you-sure-pay-invoice', {
            invoice: invoiceId,
          })}
        </Text>
        <Group mt={20} justify="right">
          <Button disabled={isLoading} size="xs" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button
            disabled={isLoading}
            size="xs"
            color="red"
            onClick={async () => {
              setIsLoading(true)
              await payInvoiceOutOfBand(invoiceId)
              onClose()
              setIsLoading(false)
            }}
          >
            {t('invoice.pay')}
          </Button>
        </Group>
      </Modal.Body>
    </Modal>
  )
}

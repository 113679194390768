import { Button, Group, Modal, Text, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import * as Sentry from '@sentry/browser'
import { useGetUserMe } from 'api/query/user'
import { Loading } from 'components/loading/loading'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface FeatureRequestModalProps {
  onClose: () => void
  opened: boolean
}

interface FormValues {
  feature: string
}

export function FeatureRequestModal({ onClose, opened }: FeatureRequestModalProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { data: user, isLoading: isLoadingUser } = useGetUserMe()
  const form = useForm<FormValues>({
    validateInputOnChange: true,
  })

  function onSubmit(values: FormValues) {
    const eventId = Sentry.captureMessage('User Feedback')

    Sentry.captureFeedback({
      message: values.feature,
      email: user?.email,
      name: user?.fullName,
      associatedEventId: eventId,
    })

    showNotification({
      title: t('feature.request-sent'),
      message: t('feature.request-sent-message'),
      color: 'teal',
    })

    form.reset()
    onClose()
  }

  return (
    <Modal size="xl" onClose={onClose} opened={opened} title={<Text size="lg">{t('feature.request')} </Text>}>
      <Modal.Body>
        {isLoadingUser && <Loading height="100%" />}
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Text size="md" mb={10}>
            {t('feature.description')}
          </Text>
          <Textarea placeholder={t('feature.start-typing')} rows={5} {...form.getInputProps('feature')} />

          <Group mt={20} justify="right">
            <Button color="" type="submit" loading={isLoading}>
              Send
            </Button>
          </Group>
        </form>
      </Modal.Body>
    </Modal>
  )
}

import { Button, Center, FileButton, Flex, Modal, Text } from '@mantine/core'
import { IconDownload, IconFileImport } from '@tabler/icons-react'
import { useImportMemberUsers } from 'api/query/user'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export function ImportUsers({ opened, close }: { opened: boolean; close: () => void }) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { mutateAsync: importUsers, isPending } = useImportMemberUsers(id)
  const [isLoading, setIsLoading] = useState(false)

  const resetRef = useRef<() => void>(null)

  const clearFile = () => {
    resetRef.current?.()
  }

  return (
    <Modal opened={opened} onClose={close} title={t('user.import.title')} size="md" padding="md">
      <Modal.Body>
        <Flex align="center" direction="column">
          <Text ta="justify" mb={20} size="sm">
            {t('user.import.description')}
          </Text>
          <Button
            size="sm"
            maw={200}
            leftSection={<IconDownload size={20} />}
            onClick={() => {
              const template = 'First Name,Last Name,Email\nJohn,Doe,john@doe.com'
              const blob = new Blob([template], { type: 'text/csv' })
              const url = URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = 'template-users.csv'
              a.click()
              URL.revokeObjectURL(url)
            }}
          >
            {t('user.import.download-template')}
          </Button>

          <Text ta="justify" mt={30} mb={20} size="sm">
            {t('user.import.ready')}
          </Text>

          <FileButton
            resetRef={resetRef}
            disabled={isPending}
            onChange={(file) => {
              if (!file) {
                return
              }

              setIsLoading(true)

              const reader = new FileReader()
              reader.addEventListener('load', async (e) => {
                const result = e.target?.result
                await importUsers(result as string)
                setIsLoading(false)
              })
              reader.readAsText(file)

              clearFile()
              close()
            }}
            accept=".csv"
          >
            {(props) => (
              <Button
                {...props}
                maw={200}
                loading={isLoading}
                disabled={isLoading}
                leftSection={<IconFileImport size={20} />}
                size="sm"
              >
                {t('user.import.import-now')}
              </Button>
            )}
          </FileButton>
        </Flex>
      </Modal.Body>
    </Modal>
  )
}

import { ActionIcon, Affix, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { User } from 'api/domain/entities/user'
import { useDeleteMemberUser, useGetUserMe, useListMemberMeUsers } from 'api/query/user'
import { AddMemberUserDrawer } from 'components/drawer/add.member.user'
import { EditMemberUserDrawer } from 'components/drawer/edit.member.user'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export function MemberUsers() {
  const { t } = useTranslation()
  const { user: currentUser } = useAuth()
  const [opened, { open, close }] = useDisclosure(false)
  const { data: users, isLoading: isLoadingUsers } = useListMemberMeUsers()
  const { mutateAsync: deleteUser } = useDeleteMemberUser()
  const [selectedMemberUser, setSelectedMemberUser] = useState<User | undefined>()

  const columns: ColumnDef<User>[] = [
    {
      id: 'id',
      accessorKey: 'id',
    },
    {
      id: 'name',
      accessorKey: 'fullName',
      header: t('user.columns.name'),
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: t('user.columns.email'),
    },
    {
      id: 'actions',
      header: t('user.columns.actions'),
      cell: (user) => {
        if (user.row.original.id === currentUser?.id) {
          return null
        }

        return (
          <>
            <ActionIcon variant="filled" onClick={() => deleteUser(user.row.original.id)}>
              <IconTrash size={17} />
            </ActionIcon>
            <ActionIcon ml={10} variant="filled" onClick={() => setSelectedMemberUser(user.row.original)}>
              <IconEdit size={17} />
            </ActionIcon>
          </>
        )
      },
    },
  ]

  if (isLoadingUsers) {
    return <Loading size="lg" />
  }

  return (
    <>
      <Title mb={30} order={2}>
        {t('member.users.title')}
      </Title>
      <Table hiddenColumns={{ id: false }} columns={columns} data={users || []} hidePagination />
      <Affix bottom={20} right={20}>
        <ActionIcon radius={100} display={opened ? 'none' : 'block'} size="xl" onClick={open}>
          <IconPlus size={17} />
        </ActionIcon>
      </Affix>
      <AddMemberUserDrawer close={close} opened={opened} />
      <EditMemberUserDrawer
        close={() => setSelectedMemberUser(undefined)}
        opened={!!selectedMemberUser}
        user={selectedMemberUser}
      />
    </>
  )
}

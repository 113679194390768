import { UserRole } from 'api/domain/entities/user'
import { useGetMember } from 'api/query/member'
import { Loading } from 'components/loading/loading'
import { useAuth } from 'hooks/useAuth'
import { isArray } from 'lodash'
import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export function RequireAuthentication({
  children,
  role,
}: {
  children: ReactNode | ReactNode[]
  needsAdminRight?: boolean
  role?: UserRole | UserRole[]
}) {
  const { user, isAuthenticated, appIsHydrated } = useAuth()
  const { data: member, isLoading: isLoadingMember } = useGetMember(user?.memberId)
  const location = useLocation()

  if (isLoadingMember) {
    return <Loading size="lg" />
  }

  let routeToRedirect = user?.isMember() ? '/member/infos' : '/dashboard'

  if (user?.isMemberManager() || user?.isMemberUser()) {
    console.log('User is member manager or member user')
    routeToRedirect = member?.hasMembership ? '/member/infos' : '/member/plans'
  }

  if (!isAuthenticated && appIsHydrated) {
    console.log('Redirecting to /session/login')
    return <Navigate to={'/session/login'} state={{ from: location }} replace />
  }

  if (role) {
    if (isArray(role)) {
      if (!role.some((r) => user?.hasRequiredRole(r))) {
        console.log('Redirecting to', routeToRedirect)
        return <Navigate to={routeToRedirect} replace />
      }
    } else {
      if (!user?.hasRequiredRole(role)) {
        console.log('Redirecting to', routeToRedirect)
        return <Navigate to={routeToRedirect} replace />
      }
    }
  }

  if (
    (user?.isMemberManager() || user?.isMemberUser()) &&
    !member?.hasMembership &&
    location.pathname !== '/member/plans'
  ) {
    console.log('Redirecting to /member/plans')
    return <Navigate to="/member/plans" replace />
  }

  return <>{children}</>
}

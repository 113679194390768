import { UserRole } from 'api/domain/entities/user'
import { Membership } from 'components/member/membership'
import { ChangePassword } from 'components/session/change-password'
import { ProvideAuth } from 'hooks/useAuth'
import { AppLayout } from 'layout/app.layout'
import { AuthLayout } from 'layout/auth.layout'
import { IndexLayout } from 'layout/index.layout'
import { MemberLayout } from 'layout/member.layout'
import { MemberInfos, MemberUsers } from 'pages/member'
import { RequireAuthentication } from 'pages/require-authentication'
import { Activation } from 'pages/session/activation'
import { ForgotPassword } from 'pages/session/forgot-password'
import { Login } from 'pages/session/login'
import { Register } from 'pages/session/register'
import { ResetPassword } from 'pages/session/reset-password'
import { Dashboard, Members, Member, Plans, Categories, Administration } from 'pages/tenant'
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

export function App() {
  return <RouterProvider router={router} />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ProvideAuth />}>
      <Route element={<IndexLayout />}>
        <Route path="session" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="activation" element={<Activation />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="register" element={<Register />} />
          <Route index element={<Navigate to="/session/login" />} />
        </Route>

        <Route
          element={
            <RequireAuthentication role={UserRole.TENANT_ADMIN}>
              <AppLayout />
            </RequireAuthentication>
          }
        >
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="members" element={<Members />} />
          <Route path="members/:id" element={<Member />} />
          <Route path="plans" element={<Plans />} />
          <Route path="categories" element={<Categories />} />
          <Route path="tenants/me" element={<Administration />} />
        </Route>

        <Route
          path="member"
          element={
            <RequireAuthentication role={[UserRole.MEMBER_MANAGER, UserRole.MEMBER_USER]}>
              <MemberLayout />
            </RequireAuthentication>
          }
        >
          <Route index element={<Navigate to="infos" replace />} />
          <Route path="infos" element={<MemberInfos />} />
          <Route path="plans" element={<Membership />} />
          <Route path="users" element={<MemberUsers />} />
          <Route path="payment-success" element={<Navigate to="plans" />} />
        </Route>

        <Route index element={<Navigate to="/dashboard" />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Route>,
  ),
)

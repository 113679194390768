export function isLocalhost() {
  return location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === ''
}

export function isPR() {
  return location.hostname === 's3.ca-central-1.amazonaws.com'
}

export function isDev() {
  return (
    location.hostname.substring(location.hostname.indexOf('.') + 1) === 'dev' ||
    location.hostname.substring(location.hostname.indexOf('.')) === 'dev'
  )
}

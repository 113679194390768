/* eslint-disable @typescript-eslint/naming-convention */
import { ThemeIcon, UnstyledButton, Group, Text, useMantineColorScheme } from '@mantine/core'
import { IconDashboard, IconMoneybag, IconUsersGroup, IconTags, IconInfoCircle } from '@tabler/icons-react'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

interface LinksProps {
  icon: React.ReactNode
  color?: string
  trans: string
  href: string
}

function MainLink({ icon, color, trans: trans, href }: LinksProps) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { colorScheme } = useMantineColorScheme()
  const navigate = useNavigate()
  const isActive = pathname.includes(href)

  return (
    <UnstyledButton
      style={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
          backgroundColor: colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },

        backgroundColor: isActive ? (colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]) : 'unset',
      })}
      onClick={() => navigate(href)}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{t(trans)}</Text>
      </Group>
    </UnstyledButton>
  )
}

const adminLinks: LinksProps[] = [
  {
    icon: <IconDashboard size="1rem" />,
    trans: 'common.menu.dashboard',
    href: '/dashboard',
  },
  {
    icon: <IconUsersGroup size="1rem" />,
    trans: 'common.menu.members',
    href: '/members',
  },
  {
    icon: <IconMoneybag size="1rem" />,
    trans: 'common.menu.plans',
    href: '/plans',
  },
  {
    icon: <IconTags size="1rem" />,
    trans: 'common.menu.categories',
    href: '/categories',
  },
]

const managerLinks: LinksProps[] = [
  {
    icon: <IconInfoCircle size="1rem" />,
    trans: 'common.menu.infos',
    href: '/member/infos',
  },
  {
    icon: <IconMoneybag size="1rem" />,
    trans: 'common.menu.plans',
    href: '/member/plans',
  },
  {
    icon: <IconUsersGroup size="1rem" />,
    trans: 'common.menu.users',
    href: '/member/users',
  },
]

const userLinks: LinksProps[] = [
  {
    icon: <IconInfoCircle size="1rem" />,
    trans: 'common.menu.infos',
    href: '/member/infos',
  },
]

export function MainLinks() {
  const { user } = useAuth()

  const listOfLinks = user?.isTenantAdmin() ? adminLinks : user?.isMemberManager() ? managerLinks : userLinks

  const links = listOfLinks.map((link) => <MainLink {...link} key={link.trans} />)
  return <>{links}</>
}

import { Category } from 'api/domain/entities/category'
import { CategoryDto } from './category-dto'

export class CategoryFactory {
  static fromDto(categoryDto: CategoryDto): Category {
    return new Category(categoryDto)
  }

  static toDto(category: Partial<Category>): Partial<CategoryDto> {
    return {
      id: category.id,
      name: category.name,
      description: category.description,
    }
  }
}

import { Button, Group, Modal, MultiSelect, Text } from '@mantine/core'
import { MemberStatus } from 'api/domain/entities/member/member-status'
import { useListCategories } from 'api/query/category'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface FilterModalProps {
  isOpen: boolean
  onClose: () => void
  filters: Record<string, any>
  onFilter: (filters: Record<string, any>) => void
}

export function FiltersModal({ isOpen, onClose, filters, onFilter }: FilterModalProps) {
  const { t } = useTranslation()
  const { data: categories } = useListCategories()
  const [currentFilters, setFilters] = useState<Record<string, any>>(filters)

  return (
    <Modal title={t('common.filters')} opened={isOpen} onClose={onClose} size="md">
      <Text size="sm">{t('filters.explanations.title')}</Text>
      <MultiSelect
        mt={10}
        label={t('filters.explanations.category')}
        data={categories
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((category) => ({ value: category.id, label: category.name }))}
        placeholder="Select categories"
        searchable
        clearable
        value={currentFilters.categoryIds}
        onChange={(categoryIds) => setFilters({ ...filters, categoryIds })}
      />
      <MultiSelect
        mt={10}
        label={t('filters.explanations.status')}
        data={Object.entries(MemberStatus)?.map(([key, value]) => ({
          value: value,
          label: t(`member.status.${value}`),
        }))}
        placeholder="Select status"
        searchable
        clearable
        value={currentFilters.statuses}
        onChange={(statuses) => setFilters({ ...filters, statuses })}
      />
      <Group mt={30} justify="flex-end">
        <Button variant="outline" size="xs" onClick={onClose}>
          {t('common.close')}
        </Button>
        <Button
          color="green"
          variant="outline"
          size="xs"
          onClick={() => {
            onFilter(currentFilters)
            onClose()
          }}
        >
          {t('common.apply-filters')}
        </Button>
      </Group>
    </Modal>
  )
}

import { Menu } from '@mantine/core'
import { feedbackIntegration } from '@sentry/react'
import { IconBug } from '@tabler/icons-react'
import { useEffect } from 'react'

export function ReportBug() {
  useEffect(() => {
    const feedback = feedbackIntegration({
      autoInject: false,
    })
    const feedbackButton = document.querySelector('#report-a-bug')

    if (!feedbackButton) {
      return
    }

    feedback.attachTo(feedbackButton, {
      formTitle: 'Report a Bug!',
    })
  }, [])

  return (
    <Menu.Item id="report-a-bug" leftSection={<IconBug size="1rem" />}>
      Report a bug
    </Menu.Item>
  )
}

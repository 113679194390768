import { ContactDto } from './contact-dto'
import { Contact } from 'api/domain/entities/contact/contact'

export class ContactFactory {
  static fromDto(contactDto: ContactDto) {
    return new Contact(contactDto)
  }

  static toDto(contact: Contact): ContactDto {
    return {
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      jobTitle: contact.jobTitle,
    }
  }
}

import { Skeleton, Text, rem } from '@mantine/core'
import { useGetTenant } from 'api/query/tenant'

export function TenantTitle() {
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenant()

  if (isLoadingTenant) {
    return <Skeleton height={rem(24)} />
  }

  return tenant && <Text size="xl">{tenant.name}</Text>
}

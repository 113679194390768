import { Box, Skeleton, Text, Title } from '@mantine/core'
import { Widget } from 'components/widget/widget'
import { useTranslation } from 'react-i18next'
import { AreaChart } from '@mantine/charts'
import { useGetTenantStats } from 'api/query/tenant'
import { MembersStats } from 'components/stats/members-stats'

export function Dashboard() {
  const { t } = useTranslation()
  const { data: stats, isLoading } = useGetTenantStats()

  return (
    <Box>
      <Title mb={30} order={2}>
        {t('dashboard.title')}
      </Title>
      <Box display="flex" sx={{ gap: 20 }}>
        <Widget>
          <Box>
            <Text size="lg" fw={700}>
              {t('tenant.stats.active-members')}
            </Text>
            {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
            {!isLoading && (
              <Text size="xl" mt={10}>
                {stats?.activeMembers || 0}
              </Text>
            )}
          </Box>
        </Widget>
        <Widget>
          <Box>
            <Text size="lg" fw={700}>
              {t('tenant.stats.inactive-members')}
            </Text>
            {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
            {!isLoading && (
              <Text size="xl" mt={10}>
                {stats?.inactiveMembers || 0}
              </Text>
            )}
          </Box>
        </Widget>
        <Widget>
          <Box>
            <Text size="lg" fw={700}>
              {t('tenant.stats.in-progress-membership')}
            </Text>
            {isLoading && <Skeleton mt={30} height={14} radius="xl" />}
            {!isLoading && (
              <Text size="xl" mt={10}>
                {stats?.inProgressMembership || 0}
              </Text>
            )}
          </Box>
        </Widget>
      </Box>
      <Box mt={50}>
        <Widget>
          <Title order={3}>{t('tenant.stats.last-6-months-revenue')}</Title>
          <AreaChart
            mt={20}
            h={300}
            data={stats?.last6monthsRevenue || []}
            title="Recurring revenue by month"
            dataKey="date"
            unit="$"
            tooltipAnimationDuration={200}
            series={[{ name: 'revenue', label: 'Revenue', color: 'custom-primary-color.6' }]}
            curveType="monotone"
            connectNulls
            withLegend
            legendProps={{ verticalAlign: 'bottom', height: 50 }}
          />
        </Widget>
      </Box>
      <Box mt={50} display="flex" sx={{ gap: 20 }}>
        <Widget>
          <Title order={6}>{t('tenant.stats.active-members-per-categories')}</Title>
          <MembersStats stats={stats?.activeMembersPerCategories || []} />
        </Widget>
        <Widget>
          <Title order={6}>{t('tenant.stats.active-members-per-plans')}</Title>
          <MembersStats stats={stats?.activeMembersPerPlan || []} />
        </Widget>
      </Box>
    </Box>
  )
}

import { Button, Flex, lighten, List, rem, Text, useMantineTheme } from '@mantine/core'
import { Box } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { Plan } from 'api/domain/entities/plan'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { useGetMember } from 'api/query/member'
import { useManageSubscription } from 'api/query/plan'
import { useAuth } from 'hooks/useAuth'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface PlanTileProps {
  index: number
  plan: Plan
  memberId?: string
  currentPlan?: Plan
  currentPriceId?: string
  interval: PriceInterval
  selectPlan: () => void
}

export function PlanTile({ plan, index, memberId, currentPlan, currentPriceId, interval, selectPlan }: PlanTileProps) {
  const { t } = useTranslation()
  const { user } = useAuth()
  const theme = useMantineTheme()
  const { data: member } = useGetMember(memberId ?? user?.memberId)
  const { mutateAsync: manageSubscription, isPending: isOpeningManage } = useManageSubscription()

  const priceWithInterval = plan.getPriceWithInterval(interval)

  const hasCurrentPlan = useMemo(() => !!currentPlan, [currentPlan])
  const isCurrentSelectedPrice = useMemo(
    () => currentPriceId === priceWithInterval?.paymentProviderId,
    [currentPriceId, priceWithInterval],
  )

  const isMostPopular = useMemo(() => plan.mostPopular, [plan.mostPopular])
  const hasSelectedColor = useMemo(
    () => isCurrentSelectedPrice || (!hasCurrentPlan && isMostPopular),
    [hasCurrentPlan, isCurrentSelectedPrice, isMostPopular],
  )
  const border = useMemo(() => {
    if (hasSelectedColor) {
      return `2px solid ${theme.colors[theme.primaryColor][4]}`
    }

    return `1px solid ${theme.colors.gray[4]}`
  }, [hasSelectedColor, theme.colors, theme.primaryColor])

  const boxColor = useMemo(() => {
    if (hasSelectedColor) {
      return lighten(theme.colors[theme.primaryColor][6], 0.6)
    }

    return theme.colors.gray[2]
  }, [hasSelectedColor, theme.colors, theme.primaryColor])

  if (!priceWithInterval) {
    return null
  }

  return (
    <Box pos="relative">
      {isMostPopular && !hasCurrentPlan && (
        <Text pos="absolute" top={-25} left={20} size="sm" c={theme.colors[theme.primaryColor][4]}>
          {t('plan.most-popular')}
        </Text>
      )}
      <Flex
        miw={350}
        maw={350}
        h="100%"
        direction="column"
        sx={{
          borderRadius: '8px',
        }}
      >
        <Box
          p="30px 20px"
          flex={0}
          sx={{
            borderLeft: border,
            borderRight: border,
            borderTop: border,
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
          }}
        >
          <Flex direction="column">
            <Text
              size="xl"
              fw={700}
              lh="24px"
              pb={5}
              c={hasSelectedColor ? theme.colors[theme.primaryColor][4] : undefined}
            >
              {plan.name}
            </Text>

            <Text fw={700} size="lg">
              {priceWithInterval ? `$${priceWithInterval.amount.toString()}` : ''}
              <Text
                ml={4}
                c="gray.6"
                component="span"
                size="12px"
              >{`/${t(`plan.interval.${interval.toLowerCase()}`).toLowerCase()}`}</Text>
            </Text>
          </Flex>

          <Text mih={80} maw={300} mt={20} size="sm">
            {plan.description}
          </Text>
          {isCurrentSelectedPrice && (
            <Button
              variant="outline"
              color="green"
              loading={isOpeningManage}
              onClick={() =>
                manageSubscription({
                  memberId: memberId || member?.id,
                })
              }
            >
              {t('member.plans.manage')}
            </Button>
          )}
          {!isCurrentSelectedPrice && (
            <Button
              data-testId={`subscribe-${index}`}
              variant="outline"
              disabled={!!member?.membership?.subscriptionScheduleId}
              onClick={() => selectPlan()}
            >
              {hasCurrentPlan ? t('plan.update-subscription') : t('plan.subscribe')}
            </Button>
          )}
        </Box>
        <Box
          bg={boxColor}
          mih={150}
          flex={1}
          p="20px 20px"
          sx={{
            borderLeft: border,
            borderRight: border,
            borderBottom: border,
            borderBottomRightRadius: '6px',
            borderBottomLeftRadius: '6px',
          }}
        >
          <Text size="sm" fw={700}>
            {plan.includedPlanName
              ? `${t('plan.included-plan', {
                  replace: { plan: plan.includedPlanName },
                })}:`
              : `${t('plan.includes')}:`}
          </Text>

          <List mt={20} spacing="md" size="sm" center icon={<IconCheck style={{ width: rem(16), height: rem(16) }} />}>
            {plan.features?.map((feature, i) => <List.Item key={`feature_${i}`}>{feature}</List.Item>)}
          </List>
        </Box>
      </Flex>
    </Box>
  )
}

import { ActionIcon, Affix, Box, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus, IconTrash } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { User } from 'api/domain/entities/user'
import { useDeleteTenantUser, useListTenantUsers } from 'api/query/user'
import { AddTenantUserDrawer } from 'components/drawer/add.tenant.user'
import { Loading } from 'components/loading/loading'
import { Table } from 'components/table'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'

export function Users() {
  const { t } = useTranslation()
  const { user: currentUser } = useAuth()
  const [opened, { open, close }] = useDisclosure(false)
  const { data: users, isLoading: isLoadingUsers } = useListTenantUsers()
  const { mutateAsync: deleteUser } = useDeleteTenantUser()

  const columns: ColumnDef<User>[] = [
    {
      id: 'id',
      accessorKey: 'id',
    },
    {
      id: 'name',
      accessorKey: 'fullName',
      header: t('user.columns.name'),
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: t('user.columns.email'),
    },
    {
      id: 'role',
      accessorKey: 'role',
      header: t('user.columns.role'),
      cell: (user) => t(`user.roles.${user.row.original.role}`),
    },
    {
      id: 'actions',
      header: t('user.columns.email'),
      cell: (user) => {
        if (user.row.original.id === currentUser?.id) {
          return null
        }

        return (
          <ActionIcon variant="filled" onClick={() => deleteUser(user.row.original.id)}>
            <IconTrash size={17} />
          </ActionIcon>
        )
      },
    },
  ]

  if (isLoadingUsers) {
    return <Loading size="lg" />
  }

  return (
    <Box mt={20}>
      <Affix bottom={20} right={20}>
        <ActionIcon data-testId="add-user" radius={100} display={opened ? 'none' : 'block'} size="xl" onClick={open}>
          <IconPlus size={17} />
        </ActionIcon>
      </Affix>
      <Table hiddenColumns={{ id: false }} columns={columns} data={users || []} hidePagination />
      <AddTenantUserDrawer close={close} opened={opened} />
    </Box>
  )
}

import { ActionIcon, Affix, Box, Button, FileButton } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconEdit, IconFileImport, IconPlus, IconTrash } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { User } from 'api/domain/entities/user'
import { useDeleteMemberUser, useImportMemberUsers, useListMemberUsers } from 'api/query/user'
import { AddMemberUserDrawer } from 'components/drawer/add.member.user'
import { EditMemberUserDrawer } from 'components/drawer/edit.member.user'
import { Loading } from 'components/loading/loading'
import { ImportUsers } from 'components/modal/import-user'
import { Table } from 'components/table'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export function Users() {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { user: currentUser } = useAuth()
  const [opened, { open, close }] = useDisclosure(false)
  const { data: users, isLoading: isLoadingUsers } = useListMemberUsers(id)
  const { mutateAsync: deleteUser } = useDeleteMemberUser(id)
  const [openedImportUsers, { open: openImportUsers, close: closeImportUsers }] = useDisclosure(false)
  const [selectedMemberUser, setSelectedMemberUser] = useState<User | undefined>()

  const actionButtons = () => [
    <Button ml={10} leftSection={<IconFileImport size={20} />} h={44} size="sm" onClick={openImportUsers}>
      {t('common.import')}
    </Button>,
  ]

  const columns: ColumnDef<User>[] = [
    {
      id: 'id',
      accessorKey: 'id',
    },
    {
      id: 'name',
      accessorKey: 'fullName',
      header: t('user.columns.name'),
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: t('user.columns.email'),
    },
    {
      id: 'role',
      accessorKey: 'role',
      header: t('user.columns.role'),
      cell: (user) => t(`user.roles.${user.row.original.role}`),
    },
    {
      id: 'actions',
      header: t('user.columns.actions'),
      cell: (user) => {
        if (user.row.original.id === currentUser?.id) {
          return null
        }

        return (
          <>
            <ActionIcon variant="filled" onClick={() => deleteUser(user.row.original.id)}>
              <IconTrash size={17} />
            </ActionIcon>
            <ActionIcon ml={10} variant="filled" onClick={() => setSelectedMemberUser(user.row.original)}>
              <IconEdit size={17} />
            </ActionIcon>
          </>
        )
      },
    },
  ]

  if (isLoadingUsers) {
    return <Loading size="lg" />
  }

  return (
    <Box mt={20}>
      <Table
        actionButtons={actionButtons}
        hiddenColumns={{ id: false }}
        columns={columns}
        data={users || []}
        hidePagination
      />
      <Affix bottom={20} right={20}>
        <ActionIcon radius={100} display={opened ? 'none' : 'block'} size="xl" onClick={open}>
          <IconPlus size={17} />
        </ActionIcon>
      </Affix>
      <AddMemberUserDrawer close={close} opened={opened} />
      <ImportUsers opened={openedImportUsers} close={closeImportUsers} />
      <EditMemberUserDrawer
        close={() => setSelectedMemberUser(undefined)}
        opened={!!selectedMemberUser}
        user={selectedMemberUser}
      />
    </Box>
  )
}

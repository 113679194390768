enum EnvName {
  LOCAL = 'local',
  DEV = 'dev',
  PROD = 'prod',
}

const globalEnv = {
  restApi: {
    baseUrl: '',
    resourceUrl: {},
  },
}

const envName = import.meta.env.VITE_ENV as EnvName

export const environment = {
  envName,
  isLocal: envName === EnvName.LOCAL,
  isDev: [EnvName.LOCAL, EnvName.DEV].includes(envName),
  isProd: [EnvName.PROD].includes(envName),
  restApi: {
    ...globalEnv.restApi,
    baseUrl: import.meta.env.VITE_API_BASE_URL,
  },
  frontendBaseURL: import.meta.env.VITE_FRONTEND_BASE_URL,
  googleAnalyticsTrackingCode: import.meta.env.VITE_GA_TRACKING_CODE,
  googleMapApiKey: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
  stripePublicKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
  publicKeyEncryption: import.meta.env.VITE_PUBLIC_KEY_ENCRYPTION,
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    org: import.meta.env.VITE_SENTRY_ORG,
    project: import.meta.env.VITE_SENTRY_PROJECT,
  },
}

import { Skeleton, Text, Image, Box } from '@mantine/core'
import { useGetTenant } from 'api/query/tenant'

export function Logo({ width = 100 }: { width?: number }) {
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenant()

  return (
    <Box display="flex">
      {isLoadingTenant && <Skeleton miw={width} height={30} circle />}
      {!isLoadingTenant ? (
        tenant?.logo ? (
          <Image h={40} src={tenant.logo} alt="logo" />
        ) : (
          <Text size="xl" fw={700}>
            {tenant?.name}
          </Text>
        )
      ) : null}
    </Box>
  )
}

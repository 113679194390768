/* eslint-disable @typescript-eslint/unbound-method */
import { AppShell, Box, Group } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { FeatureRequestModal } from 'components/feature/feature-request.modal'
import { AdminLinks } from 'components/links/admin'
import { MainLinks } from 'components/links/main-links'
import { Logo } from 'components/logo'
import { UserAvatar } from 'components/user/user-avatar'
import { UserProfileModal } from 'components/user/user-profile.modal'
import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'

export function AppLayout() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation()
  const [openedRequestModal, { open: openRequestModal, close: closeRequestModal }] = useDisclosure(false)
  const [openedUserModal, { open: openUserModal, close: closeUserModal }] = useDisclosure(false)
  const { user } = useAuth()

  return (
    <AppShell
      padding="xl"
      header={{
        height: 60,
      }}
      navbar={{ width: 250, breakpoint: 0 }}
    >
      <AppShell.Header>
        <Group style={{ height: '100%' }} px={20} justify="space-between">
          <Logo />
          <Box
            display="flex"
            sx={{
              alignItems: 'center',
            }}
          >
            <UserAvatar openRequestModal={openRequestModal} openUserModal={openUserModal} />
          </Box>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar>
        <AppShell.Section grow mt="md">
          <MainLinks />
        </AppShell.Section>
        {user?.isTenantAdmin && (
          <AppShell.Section mb="md">
            <AdminLinks />
          </AppShell.Section>
        )}
      </AppShell.Navbar>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
      <FeatureRequestModal opened={openedRequestModal} onClose={closeRequestModal} />
      <UserProfileModal isOpen={openedUserModal} onClose={closeUserModal} />
    </AppShell>
  )
}

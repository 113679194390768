import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Drawer,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconArrowDown, IconArrowUp, IconPlus, IconX } from '@tabler/icons-react'
import { Plan } from 'api/domain/entities/plan'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { PlanFactory } from 'api/dto/plan'
import { useListPlans, useUpdatePlan } from 'api/query/plan'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface EditPlanProps {
  opened: boolean
  close: () => void
  plan?: Plan
}

type PartialPlan = {
  name?: string
  description?: string
}

export function EditPlanDrawer({ opened, close, plan }: EditPlanProps) {
  const { t } = useTranslation()
  const { mutateAsync: updatePlan } = useUpdatePlan()

  const { data: plans } = useListPlans()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      ...plan,
      features: plan?.features || [],
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.name || values.name.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.description || values.description.length < 1) {
        errors.description = t('common.required')
      }

      if (values.features.length) {
        values.features.forEach((feature, index) => {
          if (feature.length < 1) {
            errors[`features.${index}`] = t('common.required')
          }
        })
      }

      return errors
    },
  })

  async function onSubmit(partialPlan: PartialPlan) {
    setIsSubmitting(true)
    const updatePlanDto = PlanFactory.toDto(partialPlan)
    try {
      await updatePlan(updatePlanDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()

    setIsSubmitting(false)
  }

  // async function onDelete() {
  //   if (!plan) return

  //   setIsDeleting(true)

  //   const deletePlanDto = PlanFactory.toDto(plan)
  //   await deletePlan(deletePlanDto)
  //   closeDeleteModal()
  //   close()
  //   form.reset()

  //   setIsDeleting(false)
  // }

  useEffect(() => {
    form.setValues({ ...plan, features: plan?.features || [] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan])

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('plan.edit')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('plan.edit-description')}</Text>
              <Box mt={10} display="flex">
                <TextInput withAsterisk w="100%" label={t('plan.fields.name')} {...form.getInputProps('name')} />
              </Box>
              <Box mt={10} display="flex">
                <TextInput
                  withAsterisk
                  w="100%"
                  label={t('plan.fields.description')}
                  {...form.getInputProps('description')}
                />
              </Box>
              <Select
                mt={10}
                label={t('plan.fields.included-plan')}
                data={plans
                  ?.filter((p) => p.id !== plan?.id)
                  .map((plan) => ({
                    value: plan.id,
                    label: plan.name,
                  }))}
                {...form.getInputProps('includedPlanId')}
              />

              <Checkbox
                mt={20}
                label={t('plan.fields.most-popular')}
                {...form.getInputProps('mostPopular')}
                checked={form.values.mostPopular}
                onChange={(event) => form.setFieldValue('mostPopular', event.currentTarget.checked)}
              />
              <Box
                sx={{
                  borderBottom: '1px solid #cecece',
                }}
              >
                <Box display="flex" pb={6} sx={{ alignItems: 'center' }} mt={20}>
                  <Text size="md" fw={600}>
                    {t('plan.features')}
                  </Text>

                  <ActionIcon
                    data-testId="add-feature"
                    ml={10}
                    size="sm"
                    onClick={() => {
                      form.insertListItem('features', '')
                    }}
                  >
                    <IconPlus size={16} />
                  </ActionIcon>
                </Box>
                <Text size="xs" fw={400} pb={10}>
                  {t('plan.feature-explanations')}
                </Text>
              </Box>

              {form.getValues().features.map((_, index) => (
                <Box mt={10} display="flex" sx={{ gap: 5, alignItems: 'center' }}>
                  <Box>
                    <ActionIcon
                      size="sm"
                      disabled={index === 0}
                      onClick={() => {
                        if (index > 0) {
                          form.reorderListItem('features', { from: index, to: index - 1 })
                        }
                      }}
                    >
                      <IconArrowUp size={16} />
                    </ActionIcon>
                    <ActionIcon
                      size="sm"
                      disabled={index === form.values.features.length - 1}
                      onClick={() => {
                        if (index < form.values.features.length - 1) {
                          form.reorderListItem('features', { from: index, to: index + 1 })
                        }
                      }}
                    >
                      <IconArrowDown size={16} />
                    </ActionIcon>
                  </Box>
                  <Textarea
                    data-testId={`feature-${index}`}
                    withAsterisk
                    rows={2}
                    w="100%"
                    {...form.getInputProps(`features.${index}`)}
                  />
                  <ActionIcon
                    onClick={() => {
                      form.removeListItem('features', index)
                    }}
                  >
                    <IconX size={20} />
                  </ActionIcon>
                </Box>
              ))}

              <Box display="flex" sx={{ borderBottom: '1px solid #cecece', alignItems: 'center' }} mt={20}>
                <Text size="md" fw={600} pb={10}>
                  {t('plan.prices')}
                </Text>
              </Box>
              {form.getValues().prices?.map((price, index) => (
                <Box mt={10} display="flex" sx={{ gap: 5, alignItems: 'center' }}>
                  <NumberInput
                    withAsterisk
                    label={t('plan.fields.price')}
                    disabled
                    {...form.getInputProps(`prices.${index}.amount`)}
                  />
                  <TextInput
                    withAsterisk
                    disabled
                    label={t('plan.fields.currency')}
                    {...form.getInputProps(`prices.${index}.currency`)}
                  />
                  <Select
                    label={t('plan.fields.interval')}
                    disabled
                    data={[
                      {
                        value: PriceInterval.MONTHLY,
                        label: t('plan.interval.monthly'),
                      },
                      {
                        value: PriceInterval.YEARLY,
                        label: t('plan.interval.yearly'),
                      },
                    ]}
                    {...form.getInputProps(`prices.${index}.interval`)}
                  />
                </Box>
              ))}
            </Box>

            <Box
              pt={20}
              display="flex"
              sx={{
                borderTop: '1px solid #cecece',
                justifyContent: 'flex-end',
              }}
              flex={0}
            >
              <Button disabled={!form.isValid() || isSubmitting} loading={isSubmitting} type="submit" size="xs">
                {t('common.update')}
              </Button>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}

/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Text, Box, Center, Switch, Space, Title, Alert } from '@mantine/core'
import { Plan } from 'api/domain/entities/plan'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { useGetMember } from 'api/query/member'
import { useListPlans, useSubscribePlan, useUpdateSubscription } from 'api/query/plan'
import { Loading } from 'components/loading/loading'
import { UpdateSubscriptionModal } from 'components/modal/update-subscription'
import { PlanTile } from 'components/plan/plan-tile'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DateTimeFormat } from 'utils/date-time'

export function Membership() {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data: member } = useGetMember(id || user?.memberId)
  const { data: plans, isLoading: isLoadingPlans } = useListPlans()
  const [intervalType, setIntervalType] = useState(PriceInterval.MONTHLY)
  const [showReloadAlert, setShowReloadAlert] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(undefined)

  const { mutateAsync: subscribeToPlan, isPending: isSubscribing } = useSubscribePlan()
  const { mutateAsync: updateSubscription, isPending: isUpdating } = useUpdateSubscription()

  const membership = member?.membership
  const currentPlanId = membership?.planId
  const currentPlan = plans?.find((p) => p.id === currentPlanId)
  const currentPriceId = membership?.priceId
  const currentPrice = currentPlan?.prices.find((p) => p.paymentProviderId === currentPriceId)
  const newPlan = plans?.find((p) => p.id === membership?.newPlanId)

  useEffect(() => {
    if (membership) {
      const plan = plans?.find((p) => p.id === membership.planId)
      if (!plan) {
        return
      }
      const price = plan.prices.find((p) => p.paymentProviderId === membership.priceId)
      if (!price) {
        return
      }
      setIntervalType(price.interval)
    }
  }, [membership, plans])

  if (isLoadingPlans) {
    return <Loading size="lg" />
  }

  if (user?.isMemberUser()) {
    return (
      <Center display="flex" sx={{ flexDirection: 'column' }}>
        <Title ta="center" maw={700}>
          {t('member.plans.not-allowed')}
        </Title>
        <Title ta="center" maw={600}>
          {t('member.plans.not-allowed-contact')}
        </Title>
      </Center>
    )
  }

  return (
    <Box pos="relative" mt={30}>
      {showReloadAlert && (
        <Alert mb={20}>
          <Center>
            <Text size="md">{t('member.plans.alert-title')}</Text>

            <Button
              data-testId="reload-button"
              ml={20}
              size="xs"
              variant="link"
              onClick={() => {
                setShowReloadAlert(false)
                window.location.reload()
              }}
            >
              {t('member.plans.reload')}
            </Button>
          </Center>
        </Alert>
      )}

      <Title ta="center">{t('member.plans.choose')}</Title>

      <Center mt={20} mb={currentPlan ? 10 : 60}>
        <Text size="md" mr={5} fw={intervalType === PriceInterval.MONTHLY ? 900 : 300}>
          {t('plan.interval.monthly')}
        </Text>
        <Switch
          size="lg"
          value={intervalType}
          onChange={(e) => setIntervalType(e.target.checked ? PriceInterval.YEARLY : PriceInterval.MONTHLY)}
          styles={{
            track: {
              padding: 10,
            },
          }}
        />
        <Text size="md" ml={5} fw={intervalType === PriceInterval.YEARLY ? 900 : 300}>
          {t('plan.interval.yearly')}
        </Text>
      </Center>

      {currentPlan && (
        <Text ta="center" mb={40}>
          {membership?.subscriptionScheduleId && (
            <Trans
              i18nKey="plan.change-plan-next-billing-period"
              components={{
                space: <Space />,
                newPlan: <Text span fw={700} />,
                date: <Text span fw={700} />,
              }}
              values={{
                nextBillingPeriod: membership?.nextBillingPeriod?.toFormat(DateTimeFormat.Human),
                newPlan: newPlan?.name,
              }}
            />
          )}
          <Trans
            i18nKey="plan.current-plan"
            components={{
              plan: <Text ml={3} mr={3} span inherit fw={700} />,
              price: <Text ml={3} span inherit fw={700} />,
              interval: <Text size="xs" mr={3} span fw={400} c="gray.7" inherit />,
              space: <Space />,
            }}
            values={{
              plan: currentPlan?.name,
              price: currentPrice?.amount,
              interval: t(`plan.interval.${currentPrice?.interval.toLowerCase()}`).toLowerCase(),
            }}
          />
          {membership?.hasUnpaidMembership ? (
            <Trans
              i18nKey="plan.unpaid-membership"
              components={{
                inactive: <Text span fw={700} inherit />,
              }}
            />
          ) : (
            <Trans
              i18nKey="plan.next-billing-period"
              components={{
                date: <Text span fw={700} inherit />,
              }}
              values={{
                date: membership?.nextBillingPeriod?.toFormat(DateTimeFormat.Human),
              }}
            />
          )}
        </Text>
      )}

      <Box
        display="grid"
        sx={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(325px, 1fr))',
          justifyItems: 'center',
          gap: '50px',
        }}
      >
        {plans?.map((plan, i) => (
          <PlanTile
            index={i}
            currentPlan={currentPlan}
            currentPriceId={currentPriceId}
            plan={plan}
            memberId={id || member?.id}
            interval={intervalType}
            selectPlan={() => setSelectedPlan(plan)}
          />
        ))}
      </Box>
      <UpdateSubscriptionModal
        isOpen={!!selectedPlan}
        onClose={() => setSelectedPlan(undefined)}
        loading={isSubscribing || isUpdating}
        onUpdatePlan={async (plan) => {
          const priceWithInterval = plan.getPriceWithInterval(intervalType)

          if (!priceWithInterval || !member) {
            return
          }

          if (currentPlan) {
            await updateSubscription({
              planId: plan.id,
              priceId: priceWithInterval.paymentProviderId,
              memberId: id || member.id,
            })
          } else {
            await subscribeToPlan({
              planId: plan.id,
              priceId: priceWithInterval.paymentProviderId,
              memberId: id || member.id,
            })
            setShowReloadAlert(true)
          }
        }}
        currentPlan={currentPlan}
        plan={selectedPlan}
        plans={plans}
      />
    </Box>
  )
}

import { Box, TextInput, MultiSelect, Textarea, Button, Group, NumberInput } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { Member, PartialMember } from 'api/domain/entities/member'
import { MemberFactory } from 'api/dto/member'
import { useListCategories } from 'api/query/category'
import { useActivateMember, useDeactivateMember, useGetMember, useUpdateMember } from 'api/query/member'
import { AddressAutocomplete } from 'components/address/address-autocomplete'
import { Loading } from 'components/loading/loading'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DateTime } from 'utils/date-time'

export function Infos() {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { data: categories, isLoading: isLoadingCategories } = useListCategories()
  const { data: member, isLoading: isLoadingMember } = useGetMember(id)
  const { mutateAsync: updateMember, isPending: isPendingMember } = useUpdateMember()
  const { mutateAsync: activateMember } = useActivateMember()
  const { mutateAsync: deactivateMember } = useDeactivateMember()
  const [isChangingStatus, setIsChangingStatus] = useState(false)

  const form = useForm<Member>({
    validate: {
      name: (value) => (value ? undefined : t('common.required')),
      phone: (value) => (value ? undefined : t('common.required')),
      address: (value) => (value ? undefined : t('common.required')),
    },
  })

  async function onSubmit(member: PartialMember) {
    const updateMemberDto = MemberFactory.toDto({
      ...member,
    })
    await updateMember(updateMemberDto)
  }

  useEffect(() => {
    if (member) {
      form.setValues(member)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member])

  if (isLoadingCategories || isLoadingMember) {
    return <Loading size="lg" />
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box mt={10} display="flex">
        <TextInput withAsterisk w="100%" label={t('member.fields.company')} {...form.getInputProps('name')} />
      </Box>

      <Box mt={10} display="flex">
        <NumberInput
          hideControls
          allowDecimal={false}
          w="100%"
          label={t('member.fields.phone')}
          {...form.getInputProps('phone')}
        />
      </Box>

      <Box mt={10} display="flex">
        <TextInput
          withAsterisk
          w="100%"
          type="email"
          label={t('member.fields.accountingEmail')}
          {...form.getInputProps('accountingEmail')}
        />
      </Box>

      <Box mt={10} display="flex">
        <AddressAutocomplete
          currentValue={form.values.address}
          setValue={(value) => form.setFieldValue('address', value)}
        />
      </Box>

      <Box mt={10} display="flex">
        <DatePickerInput
          withAsterisk
          w="100%"
          label={t('member.fields.memberSince')}
          {...form.getInputProps('memberSince')}
          value={form.values.memberSince?.date.toDate()}
          onChange={(date) => {
            form.setFieldValue('memberSince', DateTime.fromDate(date as Date))
          }}
        />
      </Box>

      <Box mt={10}>
        <MultiSelect
          label="Categories"
          data={categories
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((category) => ({ value: category.id, label: category.name }))}
          {...form.getInputProps('categoryIds')}
        />
      </Box>

      <Box mt={10} display="flex">
        <Textarea w="100%" rows={4} label={t('member.fields.note')} {...form.getInputProps('note')} />
      </Box>

      <Group pt={20} justify="space-between">
        <Button
          variant="light"
          disabled={!member || isPendingMember}
          loading={isChangingStatus}
          color={member?.isActive() ? undefined : 'green'}
          size="xs"
          onClick={async () => {
            if (!member) {
              return
            }

            setIsChangingStatus(true)

            try {
              if (member.isActive()) {
                await deactivateMember(member.id)
              } else {
                await activateMember(member.id)
              }
            } catch (error) {
              // do nothing
            }

            setIsChangingStatus(false)
          }}
        >
          {member?.isActive() ? t('common.deactivate') : t('common.activate')}
        </Button>
        <Button disabled={isChangingStatus} loading={isPendingMember} type="submit" size="xs">
          {t('common.update')}
        </Button>
      </Group>
    </form>
  )
}

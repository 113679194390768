import { showNotification } from '@mantine/notifications'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Invoice } from 'api/domain/entities/invoice/invoice'
import axios from 'config/axios'
import { globalQueryClient } from './client'

export const useListInvoices = (id?: string) =>
  useQuery({
    queryKey: ['invoices', id],
    queryFn: async () => {
      const { data } = await axios.get<{ data: Invoice[] }>('/payment/invoices', {
        params: {
          memberId: id,
        },
      })
      return data.data
    },
  })

export const usePayInvoiceOutOfBand = () => {
  return useMutation({
    mutationFn: async (invoiceId?: string) => {
      const response = await axios.post(`/payment/invoices/${invoiceId}/paid`)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Invoice paid',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['invoices'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['invoices'],
      })
    },
  })
}

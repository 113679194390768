import { Box, Button, Drawer, Group, Text, TextInput, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { CategoryFactory } from 'api/dto/category'
import { useCreateCategory } from 'api/query/category'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AddCategoryProps {
  opened: boolean
  close: () => void
}

type PartialCategory = {
  name: string
  description: string
}

export function AddCategoryDrawer({ opened, close }: AddCategoryProps) {
  const { t } = useTranslation()
  const { mutateAsync: createCategory } = useCreateCategory()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.name || values.name.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.description || values.description.length < 1) {
        errors.description = t('common.required')
      }

      return errors
    },
  })

  async function onSubmit(partialCategory: PartialCategory) {
    setIsSubmitting(true)
    const createCategoryDto = CategoryFactory.toDto(partialCategory)
    try {
      await createCategory(createCategoryDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()
    setIsSubmitting(false)
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('category.add')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('category.add-description')}</Text>
              <Box mt={10} display="flex">
                <TextInput withAsterisk w="100%" label={t('category.fields.name')} {...form.getInputProps('name')} />
              </Box>
              <Box mt={10} display="flex">
                <Textarea
                  withAsterisk
                  w="100%"
                  rows={3}
                  label={t('category.fields.description')}
                  {...form.getInputProps('description')}
                />
              </Box>
            </Box>

            <Box>
              <Group mt={20} justify="flex-end">
                <Button disabled={!form.isValid() || isSubmitting} loading={isSubmitting} type="submit" size="xs">
                  {t('common.create')}
                </Button>
              </Group>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}

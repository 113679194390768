import { Stats, TenantStatsDto } from 'api/dto/tenant/tenant-stats'
import dayjs from 'dayjs'

export class TenantStats {
  activeMembers: number
  inactiveMembers: number
  inProgressMembership: number
  last6monthsRevenue: { date: string; revenue: number }[]
  foreCastARR: number

  activeMembersPerCategories: Stats[]
  activeMembersPerPlan: Stats[]

  constructor(tenantStatsDto: TenantStatsDto) {
    this.activeMembers = tenantStatsDto.activeMembers
    this.inactiveMembers = tenantStatsDto.inactiveMembers
    this.inProgressMembership = tenantStatsDto.inProgressMembership
    this.last6monthsRevenue = Object.entries(tenantStatsDto.last6monthsRevenue)
      .map(([date, revenue]) => ({ date, revenue }))
      .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))
    this.foreCastARR = tenantStatsDto.foreCastARR

    this.activeMembersPerCategories = tenantStatsDto.activeMembersPerCategories
    this.activeMembersPerPlan = tenantStatsDto.activeMembersPerPlan
  }
}

import { ActionIcon, Checkbox, NumberInput, Select, Textarea } from '@mantine/core'
import { Box, Button, Drawer, Group, Text, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconArrowDown, IconArrowUp, IconPlus, IconX } from '@tabler/icons-react'
import { Price } from 'api/domain/entities/plan/price'
import { PriceInterval } from 'api/domain/entities/plan/price-interval'
import { PlanFactory } from 'api/dto/plan'
import { useCreatePlan, useListPlans } from 'api/query/plan'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AddPlanProps {
  opened: boolean
  close: () => void
}

type PartialPlan = {
  name: string
  description: string
  prices: Price[]
}

export function AddPlanDrawer({ opened, close }: AddPlanProps) {
  const { t } = useTranslation()
  const { mutateAsync: createPlan } = useCreatePlan()
  const { data: plans } = useListPlans()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      mostPopular: false,
      prices: [
        {
          amount: 0,
          currency: 'CAD',
          interval: PriceInterval.MONTHLY,
          paymentProviderId: '',
        },
      ],
      features: [] as string[],
    },
    validate: (values) => {
      const errors: Record<string, string> = {}

      if (!values.name || values.name.length < 1) {
        errors.name = t('common.required')
      }

      if (!values.description || values.description.length < 1) {
        errors.description = t('common.required')
      }

      if (values.prices.length < 0) {
        errors.prices = t('common.required')
        showNotification({
          title: 'Error',
          message: 'At least one price is required',
          color: 'red',
        })
      }

      return errors
    },
  })

  async function onSubmit(partialPlan: PartialPlan) {
    setIsSubmitting(true)
    const createPlanDto = PlanFactory.toDto({ ...partialPlan, order: plans?.length })
    try {
      await createPlan(createPlanDto)
    } catch (error) {
      setIsSubmitting(false)
      return
    }
    close()
    form.reset()
    setIsSubmitting(false)
  }

  return (
    <>
      <Drawer
        opened={opened}
        size="lg"
        offset={8}
        radius="md"
        onClose={close}
        title={
          <Text fw={700} size="md">
            {t('plan.add')}
          </Text>
        }
        position="right"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Drawer.Body display="flex" h="calc(100vh - 92px)" sx={{ flexDirection: 'column' }}>
            <Box
              flex={1}
              sx={{
                overflowY: 'auto',
              }}
            >
              <Text>{t('plan.add-description')}</Text>
              <TextInput mt={10} withAsterisk w="100%" label={t('plan.fields.name')} {...form.getInputProps('name')} />
              <TextInput
                mt={10}
                withAsterisk
                w="100%"
                label={t('plan.fields.description')}
                {...form.getInputProps('description')}
              />

              <Select
                mt={10}
                label={t('plan.fields.included-plan')}
                data={plans?.map((plan) => ({
                  value: plan.id,
                  label: plan.name,
                }))}
                {...form.getInputProps('includedPlanId')}
              />

              <Checkbox
                mt={20}
                label={t('plan.fields.most-popular')}
                {...form.getInputProps('mostPopular')}
                checked={form.values.mostPopular}
                onChange={(event) => form.setFieldValue('mostPopular', event.currentTarget.checked)}
              />

              <Box
                sx={{
                  borderBottom: '1px solid #cecece',
                }}
              >
                <Box display="flex" pb={6} sx={{ alignItems: 'center' }} mt={20}>
                  <Text size="md" fw={600}>
                    {t('plan.features')}
                  </Text>

                  <ActionIcon
                    data-testId="add-feature"
                    ml={10}
                    size="sm"
                    onClick={() => {
                      form.insertListItem('features', '')
                    }}
                  >
                    <IconPlus size={16} />
                  </ActionIcon>
                </Box>
                <Text size="xs" fw={400} pb={10}>
                  {t('plan.feature-explanations')}
                </Text>
              </Box>
              {form.getValues().features.map((_, index) => (
                <Box mt={10} display="flex" sx={{ gap: 5, alignItems: 'center' }}>
                  <Box>
                    <ActionIcon
                      size="sm"
                      disabled={index === 0}
                      onClick={() => {
                        if (index > 0) {
                          form.reorderListItem('features', { from: index, to: index - 1 })
                        }
                      }}
                    >
                      <IconArrowUp size={16} />
                    </ActionIcon>
                    <ActionIcon
                      size="sm"
                      disabled={index === form.values.features.length - 1}
                      onClick={() => {
                        if (index < form.values.features.length - 1) {
                          form.reorderListItem('features', { from: index, to: index + 1 })
                        }
                      }}
                    >
                      <IconArrowDown size={16} />
                    </ActionIcon>
                  </Box>
                  <Textarea
                    data-testId={`feature-${index}`}
                    withAsterisk
                    rows={2}
                    w="100%"
                    {...form.getInputProps(`features.${index}`)}
                  />
                  <ActionIcon
                    onClick={() => {
                      form.removeListItem('features', index)
                    }}
                  >
                    <IconX size={20} />
                  </ActionIcon>
                </Box>
              ))}

              <Box display="flex" sx={{ borderBottom: '1px solid #cecece', alignItems: 'center' }} mt={20}>
                <Text size="md" fw={600} pb={10}>
                  {t('plan.prices')}
                </Text>
                <ActionIcon
                  data-testId="add-price"
                  ml={10}
                  mb={10}
                  size="sm"
                  onClick={() => {
                    form.insertListItem('prices', {
                      price: 0,
                      currency: 'CAD',
                    })
                  }}
                >
                  <IconPlus size={16} />
                </ActionIcon>
              </Box>
              {form.getValues().prices.map((_, index) => (
                <Box mt={10} display="flex" sx={{ gap: 5, alignItems: 'center' }}>
                  <NumberInput
                    data-testId={`price-${index}`}
                    withAsterisk
                    label={t('plan.fields.price')}
                    {...form.getInputProps(`prices.${index}.amount`)}
                  />
                  <TextInput
                    data-testId={`price-currency-${index}`}
                    withAsterisk
                    disabled
                    label={t('plan.fields.currency')}
                    {...form.getInputProps(`prices.${index}.currency`)}
                  />
                  <Select
                    data-testId={`price-interval-${index}`}
                    label={t('plan.fields.interval')}
                    data={[
                      {
                        value: PriceInterval.MONTHLY,
                        label: t('plan.interval.monthly'),
                      },
                      {
                        value: PriceInterval.YEARLY,
                        label: t('plan.interval.yearly'),
                      },
                    ]}
                    {...form.getInputProps(`prices.${index}.interval`)}
                  />
                  <ActionIcon
                    mt={24}
                    onClick={() => {
                      form.removeListItem('prices', index)
                    }}
                  >
                    <IconX size={20} />
                  </ActionIcon>
                </Box>
              ))}
            </Box>

            <Box>
              <Group mt={20} justify="flex-end">
                <Button disabled={!form.isValid() || isSubmitting} loading={isSubmitting} type="submit" size="xs">
                  {t('common.create')}
                </Button>
              </Group>
            </Box>
          </Drawer.Body>
        </form>
      </Drawer>
    </>
  )
}

import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'config/axios'
import { useCallback } from 'react'
import { globalQueryClient } from './client'
import { showNotification } from '@mantine/notifications'
import { Member } from 'api/domain/entities/member'
import { MemberDto } from 'api/dto'

export const useListMembers = () =>
  useQuery({
    queryKey: ['members'],
    queryFn: async () => {
      const response = await axios.get('/members')
      return response.data
    },
    select: useCallback((members: MemberDto[]) => {
      return members.map((member) => new Member(member))
    }, []),
  })

export const useGetMember = (id?: string) =>
  useQuery<MemberDto, Error, Member>({
    queryKey: ['members', id],
    queryFn: async () => {
      const response = await axios.get(`/members/${id}`)
      return response.data
    },
    select: useCallback((member: MemberDto) => new Member(member), []),
    enabled: !!id,
  })

export const useCreateMember = () =>
  useMutation({
    mutationFn: async (member: Partial<MemberDto>) => {
      const response = await axios.post('/members', member)
      return response.data
    },

    onMutate: (member) => {
      globalQueryClient.setQueryData(['members'], (old: MemberDto[] | undefined) => {
        if (!old) {
          return []
        }
        return [...old, member]
      })
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Member created',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })

export const useUpdateMember = () =>
  useMutation({
    mutationFn: async (member: Partial<MemberDto>) => {
      const response = await axios.put(`/members/${member.id}`, member)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Member updated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })

export const useDeleteMember = () =>
  useMutation({
    mutationFn: async (member: Partial<MemberDto>) => {
      const response = await axios.delete(`/members/${member.id}`)
      return response.data
    },
    onSuccess: () => {
      showNotification({
        title: 'Success',
        message: 'Member deleted',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
    onError: (error) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })

export const useDeactivateMember = () =>
  useMutation({
    mutationFn: async (memberId: string) => {
      const response = await axios.put(`/members/${memberId}/deactivate`)
      return response.data
    },
    onSuccess: (_, memberId) => {
      showNotification({
        title: 'Success',
        message: 'Member deactivated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members', memberId],
      })
    },
    onError: (error, memberId) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members'],
      })
    },
  })

export const useActivateMember = () =>
  useMutation({
    mutationFn: async (memberId: string) => {
      const response = await axios.put(`/members/${memberId}/activate`)
      return response.data
    },
    onSuccess: (_, memberId) => {
      showNotification({
        title: 'Success',
        message: 'Member activated',
        color: 'green',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members', memberId],
      })
    },
    onError: (error, memberId) => {
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      })
      void globalQueryClient.invalidateQueries({
        queryKey: ['members', memberId],
      })
    },
  })

import { Box, TextInput, Button, Group, ColorSwatch, Image } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Tenant } from 'api/domain/entities/tenant'
import { TenantFactory } from 'api/dto/tenant'
import { useGetTenant, useUpdateTenant } from 'api/query/tenant'
import { Loading } from 'components/loading/loading'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function Infos() {
  const { t } = useTranslation()
  const { data: tenant, isLoading: isLoadingTenant } = useGetTenant()
  const { mutateAsync: updateTenant, isPending: isPendingTenant } = useUpdateTenant()

  const form = useForm<Tenant>({
    validate: {
      name: (value) => (value ? undefined : t('common.required')),
      slug: (value) => (value ? undefined : t('common.required')),
      supportEmail: (value) => (value ? undefined : t('common.required')),
      logo: (value) => (value ? undefined : t('common.required')),
      banner: (value) => (value ? undefined : t('common.required')),
      theme: (value) => (value ? undefined : t('common.required')),
    },
  })

  async function onSubmit(tenant: Tenant) {
    const updateTenantDto = TenantFactory.toDto({
      ...tenant,
    })
    await updateTenant(updateTenantDto)
  }

  useEffect(() => {
    if (tenant) {
      form.setValues(tenant)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  if (isLoadingTenant) {
    return <Loading size="lg" />
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Box mt={10} display="flex">
        <TextInput w="100%" label={t('tenant.fields.name')} {...form.getInputProps('name')} />
      </Box>

      <Box mt={10} display="flex">
        <TextInput disabled w="100%" label={t('tenant.fields.slug')} {...form.getInputProps('slug')} />
      </Box>

      <Box mt={10} display="flex">
        <TextInput
          w="100%"
          type="email"
          label={t('tenant.fields.supportEmail')}
          {...form.getInputProps('supportEmail')}
        />
      </Box>

      <Box mt={10} display="flex">
        <TextInput w="100%" label={t('tenant.fields.logo')} {...form.getInputProps('logo')} />
      </Box>

      <Box mt={10} display="flex">
        <Image src={form.values.logo} h={100} />
      </Box>

      <Box mt={10} display="flex">
        <TextInput w="100%" label={t('tenant.fields.banner')} {...form.getInputProps('banner')} />
      </Box>

      <Box mt={10} display="flex">
        <Image src={form.values.banner} h={100} />
      </Box>

      <Box mt={10} display="flex">
        <TextInput w="100%" label={t('tenant.fields.theme')} {...form.getInputProps('theme')} />
      </Box>

      <Box mt={10} display="flex">
        {form.values.theme?.split(',').map((color) => <ColorSwatch color={color} size={50} mr="xs" />)}
      </Box>

      <Group pt={20} justify="right">
        <Button loading={isPendingTenant} type="submit" size="xs">
          {t('common.update')}
        </Button>
      </Group>
    </form>
  )
}

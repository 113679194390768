import { environment } from 'config/environment'
import ReactGA from 'react-ga4'

const init = () => {
  if (environment.isLocal) {
    return
  }
  ReactGA.initialize(environment.googleAnalyticsTrackingCode)
}

export default {
  init,
}
